import React from "react";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";

import { SubTopMenu } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";
import { Tabs, useIsMobileWidth, useTheme } from "@equiem/react-admin-ui";
import { RiArrowLeftLine } from "@equiem/react-admin-ui/icons";

interface TabProps {
  children: React.ReactNode;
}

export const SettingsTabs: React.FC<TabProps> = ({ children }) => {
  const { colors, breakpoints } = useTheme(true);
  const router = useRouter();
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();

  const tabs: TabItem[] = [
    { title: t("requests.status.statuses"), key: "/request-management/settings" },
    { title: t("requests.category.categories"), key: "/request-management/settings/categories" },
  ];
  const handleTabSelect = (key: string) => {
    void router.push(key);
  };

  return (
    <>
      <Head>
        <title>{t("common.settings")}</title>
      </Head>
      <div className="cont">
        <SubTopMenu btmPadding={false} topPadding={false} className="top-menu">
          {isMobile && (
            <Link href="/request-management" passHref legacyBehavior>
              <a className="mr-3 ml-3">
                <RiArrowLeftLine size={16} color={colors.grayscale[60]} />
              </a>
            </Link>
          )}
          <h1 className="mb-3 font-weight-bold">{t("common.settings")}</h1>
        </SubTopMenu>
        <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
          <Tabs items={tabs} selected={router.pathname} tabLinks onSelect={handleTabSelect} />
        </SubTopMenu>
        <div className="p-6">{children}</div>
      </div>
      <style jsx>{`
        .cont {
          background: ${colors.white};
          min-height: 100%;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .cont h1 {
            margin-bottom: 0 !important;
          }
          .cont :global(.top-menu) {
            width: 100%;
            justify-content: flex-start !important;
          }
        }
      `}</style>
    </>
  );
};
