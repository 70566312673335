import type { FC } from "react";
import React, { useMemo, useState } from "react";
import { UserSearch } from "@equiem/react-admin-ui";
import type { ListProfilesQuery } from "../generated/gateway-client";
import { useListProfilesQuery } from "../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

export type UserSearchUser = NonNullable<ListProfilesQuery["listProfiles"]["edges"][number]["node"]>;

interface Props {
  siteUuids?: string[];
  onSelect?: (item: UserSearchUser) => void;
  disabled?: boolean;
  placeholder?: string;
}

export const UserSearchHOC: FC<Props> = ({ siteUuids, onSelect, disabled, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const { data, loading } = useListProfilesQuery({
    variables: {
      page: { first: 10 },
      filter: {
        name: searchTerm,
        email: searchTerm,
        active: true,
      },
      siteUuids,
    },
    skip: searchTerm === "",
  });
  const userData = data?.listProfiles.edges;

  const searchUsers = useMemo(
    () =>
      (userData ?? [])
        .flatMap((edge) => (edge.node == null ? [] : [edge.node]), [])
        .map((user) => ({ value: "", user })),
    [userData],
  );

  return (
    <>
      <UserSearch
        disabled={disabled}
        items={searchUsers}
        loading={loading}
        onSearch={setSearchTerm}
        onSelect={({ user }) => {
          if (onSelect != null) {
            onSelect(user);
          }
        }}
        buttonText={t("common.add")}
        placeholder={placeholder}
      />
    </>
  );
};
