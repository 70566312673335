import { stringNotEmpty } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Button, DateTime as DT, Form, Text, useTheme } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { ListingTimesQuery } from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { BookingCalendarContext } from "../contexts/BookingCalendarContext";
import type { BookingFormValue } from "../models/BookingFormValue";
import { RiArrowDownSLine, RiArrowUpSLine } from "@equiem/react-admin-ui/icons";

const MAX_DISPLAY = 10;
type QueryListingTime = ListingTimesQuery["bookableResource"]["listingTimes"][number];
type ListingTime = Omit<QueryListingTime, "times"> & {
  times: Array<
    QueryListingTime["times"][number] & {
      startHourMin: string;
      endHourMin: string;
    }
  >;
};

const findSlot = (listingTimes: ListingTime[], defaultTime: { start: string; end: string }) => {
  for (const [listingIndex, listing] of listingTimes.entries()) {
    for (const [timeIndex, time] of listing.times.entries()) {
      if (time.startHourMin === defaultTime.start && time.endHourMin === defaultTime.end) {
        return `${listingIndex}-${timeIndex}`;
      }
    }
  }
  return null;
};

interface SP {
  active: string | null;
  listingTime: ListingTime;
  listingTimeIndex: number;
  onSlotsSelectionChange: (start: string, end: string, index: string) => void;
}
const SlotTime: React.FC<SP> = ({ active, listingTime, listingTimeIndex, onSlotsSelectionChange }) => {
  const { colors, spacers } = useTheme();
  const { t, i18n } = useTranslation();
  const { timezone } = useContext(BookingModalInfo);
  const [showAll, setShowAll] = useState(listingTime.times.length < MAX_DISPLAY);
  const showLess = useCallback(() => setShowAll(false), [setShowAll]);
  const showMore = useCallback(() => setShowAll(true), [setShowAll]);
  const times = useMemo(() => {
    return showAll ? listingTime.times : listingTime.times.slice(0, MAX_DISPLAY);
  }, [listingTime.times, showAll]);

  // Deal with the changes of the active value.
  useEffect(() => {
    if (active == null) {
      return;
    }

    const index = listingTime.times.findIndex((_ti, tindex) => {
      const idx = `${listingTimeIndex}-${tindex}`;
      return idx === active;
    });

    // When the index of selected is hidden then show all.
    if (index >= 0 && index + 1 > MAX_DISPLAY) {
      showMore();
    }

    // We don't want the setTimes always
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <>
      <div key={listingTimeIndex} className="time-cont">
        <div className="title">
          <Text variant="label" color={colors.grayscale[60]} className="mr-2">
            {stringNotEmpty(listingTime.title) && `${listingTime.title} `}(
            {times.length > 0 && (
              <>
                {formatters.durationshort((times[0].end - times[0].start) / 1000 / 60, i18n.language)}{" "}
                {t("bookings.resources.slots")}
              </>
            )}
            )
          </Text>
        </div>
        <div className="slots-times">
          {times.length === 0 && <>{t("bookings.operations.noAvailability")}</>}
          {times.map((ti, tindex) => {
            const idx = `${listingTimeIndex}-${tindex}`;

            return (
              <div key={tindex}>
                <Button
                  type="button"
                  size="lg"
                  shape="capsule"
                  variant="toggle"
                  className={active === idx ? "active" : undefined}
                  onClick={() => onSlotsSelectionChange(ti.startHourMin, ti.endHourMin, idx)}
                >
                  <DT.TimeDisplay datetime={ti.start} language={i18n.language} timezone={timezone} />
                </Button>
              </div>
            );
          })}
        </div>
        {listingTime.times.length > MAX_DISPLAY && (
          <div className="text-center pt-3">
            {showAll ? (
              <Button variant="ghost" onClick={showLess}>
                {t("bookings.operations.showLess")} <RiArrowUpSLine />
              </Button>
            ) : (
              <Button variant="ghost" onClick={showMore}>
                {t("bookings.operations.showMore")} (+{listingTime.times.length - times.length}) <RiArrowDownSLine />
              </Button>
            )}
          </div>
        )}
      </div>
      <style jsx>{`
        .time-cont:first-child {
          padding-top: 0;
        }
        .time-cont {
          padding-top: ${spacers.s6};
        }
        .title {
          border-bottom: 1px solid ${colors.border};
          padding: 0 0 ${spacers.s3};
          margin: 0 0 ${spacers.s3};
        }
        .slots-times {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
          gap: ${spacers.s3};
        }
        .slots-times :global(button) {
          width: 100%;
          text-transform: none;
        }
        .slots-times :global(button.active) {
          background-color: ${colors.blue[10]};
          border-color: ${colors.blue[60]};
        }
      `}</style>
    </>
  );
};

interface P {
  listingTimes: ListingTime[];
  date: number;
}
export const BookingFormSlotsTime: React.FC<P> = ({ listingTimes, date }) => {
  const { t } = useTranslation();
  const { setSelectedTime } = useContext(BookingCalendarContext);
  const fm = useFormikContext<BookingFormValue>();
  const [active, setActive] = useState<string | null>(null);

  const locateSlot = (start: string, end: string) => {
    return findSlot(listingTimes, { start, end });
  };

  // set the initially selected slot once the listings load
  const [slotSelectionDoneForDate, setSlotSelectionDoneForDate] = useState(0);
  useEffect(() => {
    if (slotSelectionDoneForDate === date) {
      return;
    }

    const newSlot =
      fm.values.start != null && fm.values.end != null ? locateSlot(fm.values.start, fm.values.end) : null;
    const startVal = newSlot == null ? "" : fm.values.start ?? "";
    const endVal = newSlot == null ? "" : fm.values.end ?? "";
    setSelectedTime({ start: startVal, end: endVal });
    fm.setValues({
      ...fm.values,
      start: startVal,
      end: endVal,
    }).catch(console.error);

    setActive(newSlot);

    setSlotSelectionDoneForDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotSelectionDoneForDate, date, fm.values.end, fm.values.start]);

  const onSlotsSelectionChange = (start: string, end: string, index: string) => {
    const deselect = active === index;

    const startVal = deselect ? "" : start;
    const endVal = deselect ? "" : end;
    const indexVal = deselect ? null : index;

    setSelectedTime({ start: startVal, end: endVal });
    fm.setValues({
      ...fm.values,
      start: startVal,
      end: endVal,
    }).catch(console.error);

    setActive(indexVal);
  };

  const startError = fm.touched.start === true ? fm.errors.start : undefined;
  const endError = fm.touched.end === true ? fm.errors.end : undefined;
  const groupError = [startError, endError].filter((x) => x != null).join(", ");

  return (
    <>
      <Form.Group label={t("common.time")} error={groupError} required>
        {listingTimes.map((lt, ltindex) => (
          <SlotTime
            active={active}
            listingTime={lt}
            listingTimeIndex={ltindex}
            key={ltindex}
            onSlotsSelectionChange={onSlotsSelectionChange}
          />
        ))}
      </Form.Group>
    </>
  );
};
