import React, { useContext } from "react";
import { String } from "runtypes";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, ColumnView, Text, Tooltip, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiAddCircleLine, RiAddLine, RiDeleteBinLine, RiEditLine } from "@equiem/react-admin-ui/icons";

import { CategoryModal } from "../../components/CategoryModal";

import { ModalContext } from "./contexts/ModalContext";
import { withContexts } from "./contexts/withContexts";
import { useCategoryData } from "./hooks/useCategoryData";
import { SettingsTabs } from "./SettingsTabs";

const CategoryComponent: React.FC = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const { colors } = useTheme();
  const modal = useContext(ModalContext);
  const toast = useToast();
  const showError = useShowError();
  const { withConfirmation } = useConfirmer();

  const {
    categories,
    categoriesLoading,
    selectedCategoryUuid,
    setSelectedCategoryUuid,
    selectedSubCategoryUuid,
    setSelectedSubCategoryUuid,
    deleteCategoryMutation,
    subCategories,
    subCategoriesLoading,
    showSubCategoryAdder,
    setShowSubCategoryAdder,
    editingSubCategoryUuid,
    setEditingSubCategoryUuid,
    createSubCategoryMutation,
    createSubCategoryLoading,
    updateSubCategoryMutation,
    updateSubCategoryLoading,
    deleteSubCategoryMutation,
  } = useCategoryData();

  const onClickCategoryModal = (id?: string) => {
    modal.open("CategoryModal", id);
  };

  return (
    <>
      <CategoryModal />
      <div className="container">
        <SettingsTabs>
          <Text variant="heading" size="medium" className="mb-3">
            {t("requests.category.configureCategories")}
          </Text>
          <ColumnView.Container>
            <ColumnView.Column
              className="bc-column"
              id="category"
              title={t("requests.category.categories")}
              loading={categoriesLoading}
              tooltip={t("requests.category.description")}
              actions={[
                <Tooltip title={t("requests.category.addCategory")} key="addCategory" focusable={false}>
                  <Button
                    round
                    variant="ghost"
                    aria-label={t("requests.category.addCategory")}
                    onClick={() => {
                      onClickCategoryModal();
                    }}
                  >
                    <RiAddCircleLine size={16} />
                  </Button>
                </Tooltip>,
              ]}
              empty={categories.length === 0}
              emptySettings={{
                message: t("requests.category.noCategoryHasBeenAdded"),
                action: (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      onClickCategoryModal();
                    }}
                  >
                    <RiAddLine /> {t("requests.category.addCategory")}
                  </Button>
                ),
              }}
            >
              {categories.map((category) => (
                <ColumnView.Item
                  value={category.name}
                  key={category.uuid}
                  onSelect={() => {
                    setSelectedCategoryUuid(category.uuid);
                    setShowSubCategoryAdder(false);
                    setSelectedSubCategoryUuid(undefined);
                  }}
                  selected={category.uuid === selectedCategoryUuid}
                  maxIcons={1}
                  actions={[
                    {
                      title: t("common.edit"),
                      icon: RiEditLine,
                      onClick: () => {
                        onClickCategoryModal(category.uuid);
                      },
                    },
                    {
                      title: t("common.delete"),
                      icon: RiDeleteBinLine,
                      color: colors.status.danger.primary,
                      onClick: withConfirmation({
                        onConfirm: () => {
                          deleteCategoryMutation({ variables: { uuid: category.uuid } })
                            .then(() => {
                              setSelectedCategoryUuid(undefined);
                              toast.neutral(
                                t("requests.category.deleteCategorySuccess", { categoryName: category.name }),
                              );
                            })
                            .catch(showError);
                        },
                        title: String.check(t("common.deleteConfirmationTitleWithTitle", { title: category.name })),
                        message: String.check(t("common.deleteConfirmationWithTitle", { title: category.name })),
                        confirmButtonText: t("common.yesDelete"),
                        confirmButtonVariant: "danger",
                      }),
                    },
                  ]}
                />
              ))}
            </ColumnView.Column>
            <ColumnView.Column
              className="bc-column"
              id="subcategory"
              title={t("requests.category.subcategories")}
              loading={selectedCategoryUuid != null && subCategoriesLoading}
              tooltip={t("requests.category.subCategoryDescription")}
              actions={[
                <Tooltip title={t("requests.category.addSubcategory")} key="addCategory">
                  <Button
                    variant="ghost"
                    aria-label={t("requests.category.addSubcategory")}
                    onClick={() => setShowSubCategoryAdder(true)}
                    disabled={selectedCategoryUuid == null}
                    round
                  >
                    <RiAddCircleLine size={16} />
                  </Button>
                </Tooltip>,
              ]}
              empty={selectedCategoryUuid != null && subCategories.length === 0 && !showSubCategoryAdder}
              emptySettings={{
                message: t("requests.category.noSubCategoryHasBeenAdded"),
                action: (
                  <Button variant="secondary" onClick={() => setShowSubCategoryAdder(true)}>
                    <RiAddLine /> {t("requests.category.addSubcategory")}
                  </Button>
                ),
              }}
            >
              {[
                ...subCategories.map((subCategory) => (
                  <ColumnView.Item
                    value={subCategory.name}
                    key={subCategory.uuid}
                    loading={updateSubCategoryLoading}
                    selected={subCategory.uuid === selectedSubCategoryUuid}
                    isEditing={subCategory.uuid === editingSubCategoryUuid}
                    onChange={(name) => {
                      updateSubCategoryMutation({ variables: { name, uuid: subCategory.uuid } })
                        .then(() => {
                          setSelectedSubCategoryUuid(subCategory.uuid);
                          setEditingSubCategoryUuid(undefined);
                          toast.neutral(t("common.changesSaved"));
                        })
                        .catch(showError);
                    }}
                    onReset={() => setEditingSubCategoryUuid(undefined)}
                    maxIcons={1}
                    actions={[
                      {
                        title: t("common.edit"),
                        disabled: false,
                        icon: RiEditLine,
                        onClick: () => {
                          setEditingSubCategoryUuid(subCategory.uuid);
                        },
                      },
                      {
                        title: t("common.delete"),
                        disabled: false,
                        icon: RiDeleteBinLine,
                        loading: false,
                        color: colors.status.danger.primary,
                        onClick: withConfirmation({
                          onConfirm: () => {
                            deleteSubCategoryMutation({ variables: { uuid: subCategory.uuid } })
                              .then(() => {
                                toast.neutral(
                                  t("requests.category.deleteSubCategorySuccess", {
                                    subCategoryName: subCategory.name,
                                  }),
                                );
                              })
                              .catch(showError);
                          },
                          title: t("common.deleteConfirmationTitleWithTitle", { title: subCategory.name }) as string,
                          message: t("common.deleteConfirmationWithTitle", { title: subCategory.name }) as string,
                          confirmButtonText: t("common.yesDelete"),
                          confirmButtonVariant: "danger",
                        }),
                      },
                    ]}
                  />
                )),
                showSubCategoryAdder ? (
                  <ColumnView.Item
                    isNew
                    loading={createSubCategoryLoading}
                    value=""
                    onChange={(name) => {
                      if (selectedCategoryUuid == null) {
                        return;
                      }
                      createSubCategoryMutation({ variables: { name, categoryUuid: selectedCategoryUuid } })
                        .then((newSubCategory) => {
                          setSelectedSubCategoryUuid(newSubCategory.data?.reqMgt.createSubCategory.uuid);
                          toast.positive(t("requests.category.newSubCategoryAdded"));
                          setShowSubCategoryAdder(false);
                        })
                        .catch(showError);
                    }}
                    onReset={() => setShowSubCategoryAdder(false)}
                  />
                ) : null,
              ]}
            </ColumnView.Column>
          </ColumnView.Container>
        </SettingsTabs>
      </div>
      <style jsx>{`
        .container :global(.bc-column) {
          height: calc(100vh - 320px);
        }

        @media (max-width: ${breakpoints.md}px) {
          .container :global(.bc-column) {
            height: calc(100vh - 250px);
          }
        }

        @media (max-width: ${breakpoints.lg}px) {
          .container :global(.grid-container) {
            min-width: calc(88vw);
          }
        }
      `}</style>
    </>
  );
};

export const Category = withContexts(CategoryComponent);
