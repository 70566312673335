/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";

import type { SiteBookingsCalendarMultiDayQuery } from "../generated/gateway-client";
import { BookingStatus, useSiteBookingsCalendarMultiDayQuery } from "../generated/gateway-client";
import type { SiteBookingsFilters } from "../pages/operations/hooks/useSiteBookingsFilters";
import { filterCalendarBookings } from "../pages/operations/models/BookingFilters";

export type SiteCalendarDay = NonNullable<SiteBookingsCalendarMultiDayQuery["siteBookingsCalendar"][number]>;

type BookingsByDay = Partial<Record<string, SiteCalendarDay>>;

interface Params {
  startDate: number;
  resolution: "5D" | "7D" | "1M";
  filters: SiteBookingsFilters;
  searchText: string;
  skip?: boolean;
}

const dateKey = (date: number, timezone: string) =>
  DateTime.fromMillis(date, { zone: timezone }).toFormat("yyyy-MM-dd");

export function useMultiDayBookingsCalendar({ startDate, resolution, filters, searchText, skip }: Params) {
  const { uuid: siteUuid, timezone } = useSiteContext();
  const { i18n } = useTranslation();

  const dt = DateTime.fromMillis(startDate, { zone: timezone });

  let endDate: number;
  let maxBookingsPerDay: number;
  let daysInView: number;
  switch (resolution) {
    case "1M":
      endDate = dt.endOf("month").toMillis();
      maxBookingsPerDay = 4;
      // Here be dragons: You'd think we could use `Math.ceil` here, but Luxon
      // will consider DST in `diff`, so this may be slightly more or less than
      // `$numDaysInMonth - (1ms / $msPerDay)` depending on timezone and
      // government policy.
      daysInView = Math.round(dt.endOf("month").diff(dt).as("days"));
      break;
    case "7D":
      endDate = dt.endOf("week").toMillis();
      maxBookingsPerDay = 30;
      daysInView = 7;
      break;
    case "5D":
      endDate = dt.plus({ days: 4 }).endOf("day").toMillis();
      maxBookingsPerDay = 30;
      daysInView = 5;
      break;
    default:
      throw new Error(`unexpected resolution: ${resolution}`);
  }

  const { startDate: _startDate, endDate: _endDate, status: _status, ...applicableFilters } = filters;
  const { error, data, loading } = useSiteBookingsCalendarMultiDayQuery({
    variables: {
      ...applicableFilters,
      siteUuid: [siteUuid],
      date: startDate,
      endDate,
      status: [BookingStatus.Approved],
      maxBookingsPerDay,
    },
    skip,
    fetchPolicy: "cache-and-network",
  });

  const bookingsByDay = (data?.siteBookingsCalendar ?? []).reduce<BookingsByDay>((acc, day) => {
    const key = dateKey(day.date, timezone);
    const filteredBookings = filterCalendarBookings(day.bookings, searchText, timezone, i18n.language);
    return {
      ...acc,
      [key]: { ...day, bookings: filteredBookings },
    };
  }, {});

  const calendar: SiteCalendarDay[] = Array(daysInView)
    .fill(DateTime.fromMillis(startDate, { zone: timezone }))
    .map((_, i) => dt.plus({ days: i }).toMillis())
    .map((date) => bookingsByDay[dateKey(date, timezone)] ?? { date, bookings: [], numMore: 0 });

  return { error, loading, calendar };
}
