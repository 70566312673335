import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { useDisabledSiteSwitcher } from "@equiem/lib";
import {
  Button,
  DateTime as DateTimeUi,
  EmptyState,
  ProgressCircle,
  Text,
  useDebounced,
  useTheme,
} from "@equiem/react-admin-ui";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CalendarsCheckFillIcon } from "./components/icons/CalendarsCheckFillIcon";
import { MyBookingCard, MyBookingCardLoading } from "./components/MyBookingCard";
import type { Tab } from "./components/MyBookingsStatusTabs";
import { MyBookingsStatusTabs } from "./components/MyBookingsStatusTabs";
import type { MyBookingsQuery } from "../../generated/gateway-client";
import { BookingSortOrder } from "../../generated/gateway-client";
import { usePagedMyBookings } from "../../hooks/usePagedMyBookings";
import { withContexts } from "../../contexts/withContexts";
import { BookingViewModal } from "../operations/components/BookingViewModal";
import { BookingsTab } from "../../components/BookingsTab";
import { matchesSearchText } from "../../lib/matchesSearch";
import { ScrollBottomObserverWrapper } from "../../components/ScrollBottomObserverWrapper";
import { BookingModal } from "../operations/contexts/BookingModalContext";

type MyBooking = NonNullable<MyBookingsQuery["myBookingsList"]["edges"][number]["node"]>;

const DEBOUNCE_MS = 100;

const EmptyMyBookings = () => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();
  const router = useRouter();

  const browseCatalogue = useCallback(async () => router.push("/bookings"), [router]);

  return (
    <>
      <div className="empty-my-bookings-icon">
        <CalendarsCheckFillIcon size="24" color={colors.transparent.black[40]} />
      </div>
      <div>
        <Text variant="text" size="small" color={colors.transparent.black[40]} className="my-0 text-center">
          {t("bookings.operations.noBookingsYet")}
        </Text>
        <Text variant="text" size="small" color={colors.transparent.black[40]} className="my-0 text-center">
          {t("bookings.operations.getStartedByBrowsingCatalogue")}
        </Text>
      </div>
      <Button
        variant="secondary"
        size="lg"
        onClick={() => {
          browseCatalogue().catch(console.log);
        }}
      >
        {t("bookings.operations.browse")}
      </Button>
      <style jsx>{`
        .empty-my-bookings-icon {
          display: flex;
          padding: ${spacers.s5};
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          background: ${colors.transparent.black[5]};
        }
      `}</style>
    </>
  );
};

const MyBookingsLocal = () => {
  const { i18n } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const { breakpoints, spacers } = useTheme();
  const [currentTime, setCurrentTime] = useState(Date.now());

  if (process.env.bookingMultiSiteEnabled === "true") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDisabledSiteSwitcher();
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const { bookings, hasMoreData, error, loading, fetchMore, loadingMore } = usePagedMyBookings({
    sort: BookingSortOrder.StartdateDescending,
  });
  const loadingMoreDebounced = useDebounced(loadingMore, DEBOUNCE_MS);

  const onBottomVisible = () => {
    if (hasMoreData && !loading) {
      fetchMore();
    }
  };

  const getSearchTokens = useCallback(
    (b: MyBooking, language: string) => [
      b.title ?? "",
      b.reference,
      b.resource.name,
      DateTimeUi.toDateString({ datetime: b.startDate, timezone: b.timezone }),
      DateTimeUi.toTimeRangeString({ start: b.startDate, end: b.endDate, timezone: b.timezone, language }),
      b.status.toLowerCase().replaceAll("_", " "),
    ],
    [],
  );

  const [searchText, setSearchText] = useState("");
  const [activeTab, setActiveTab] = useState<Tab>("all");
  const myBookingsToShow = bookings.filter(
    (b: MyBooking) =>
      (activeTab === "all" ||
        (activeTab === "upcoming" && b.startDate >= currentTime && !b.cancelled) ||
        (activeTab === "past" && b.startDate < currentTime && !b.cancelled) ||
        (activeTab === "cancelled" && b.cancelled)) &&
      matchesSearchText(searchText, getSearchTokens(b, i18n.language)),
  );

  // Open/close booking side modal based on query param.
  const router = useRouter();
  const modal = useContext(BookingModal);
  useEffect(() => {
    if (typeof router.query.booking === "string") {
      if (modal.id !== router.query.booking) {
        modal.setDisplayMode("view");
        modal.open(router.query.booking);
      }
    } else if (modal.id != null) {
      modal.close();
    }
  }, [modal, router]);

  const handleTabFilterChange = (newTab: Tab) => {
    setSearchText("");
    setActiveTab(newTab);
  };

  return (
    <BookingsTab
      title={<MyBookingsStatusTabs activeTab={activeTab} setActiveTab={handleTabFilterChange} />}
      search={{ searchText, setSearchText }}
    >
      <ScrollBottomObserverWrapper onBottomVisible={onBottomVisible}>
        <InfiniteScroll
          next={fetchMore}
          dataLength={myBookingsToShow.length}
          hasMore={hasMoreData}
          style={{ overflow: undefined }}
          loader={null}
        >
          {loading || error != null || myBookingsToShow.length > 0 ? (
            <div className="listing">
              {error != null && <div>{tError(error)}</div>}
              {loading && Array.from({ length: 6 }).map((_, index) => <MyBookingCardLoading key={index} />)}
              {!loading &&
                error == null &&
                myBookingsToShow.map((booking) => (
                  <MyBookingCard key={booking.reference} {...booking} currentTime={currentTime} />
                ))}
            </div>
          ) : (
            <div className="empty-listing">
              {loadingMoreDebounced ? (
                <ProgressCircle size="lg" />
              ) : bookings.length > 0 ? (
                <EmptyState />
              ) : (
                <EmptyMyBookings />
              )}
            </div>
          )}
        </InfiniteScroll>
      </ScrollBottomObserverWrapper>
      <BookingViewModal />
      <style jsx>{`
        .listing {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
          justify-items: center;
          grid-gap: ${spacers.s7} ${spacers.s5};
          padding: ${spacers.s4} ${spacers.s7} ${spacers.s7};
          opacity: ${loadingMoreDebounced ? "0.5" : "1"};
        }
        .empty-listing {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
          padding: ${spacers.s7};
          width: 100%;
          align-items: center;
          align-self: center;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .listing {
            grid-template-columns: 1fr;
          }
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .listing {
            padding: ${spacers.s5};
          }
        }
      `}</style>
    </BookingsTab>
  );
};

export const MyBookings = withContexts(MyBookingsLocal);
