import { useCallback, useEffect, useMemo, useState } from "react";

import { useShowError } from "@equiem/lib";
import type { ReqMgtCreateCategoryInput } from "@equiem/lib/generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { useDebounced, useToast } from "@equiem/react-admin-ui";

import type { StatusFragment } from "../../../generated/requests-client";
import {
  ReqMgtStatusType,
  useCreateStatusMutation,
  useDeleteStatusMutation,
  useStatusesQuery,
  useUpdateStatusMutation,
  useUpdateStatusOrderMutation,
} from "../../../generated/requests-client";
import type { Group, Status } from "../types";

export function useStatusData() {
  const NEW_ID = "new";
  const toast = useToast();
  const { t } = useTranslation();
  const showError = useShowError();

  const { data, loading, error } = useStatusesQuery();
  const [createMutation, { loading: createLoading }] = useCreateStatusMutation();
  const [updateMutation, { loading: updateLoading }] = useUpdateStatusMutation();
  const [deleteMutation, { loading: deleteLoading }] = useDeleteStatusMutation();
  const [orderMutation, { loading: orderingLoading }] = useUpdateStatusOrderMutation();

  const [selectedType, setSelectedType] = useState<ReqMgtStatusType | null>(null);
  const [editingStatus, setEditingStatus] = useState<Status>({});
  const [group, setGroup] = useState<Group>({});

  const [orderedUuids, setOrderedUuids] = useState<string[]>([]);
  const uuids = useDebounced(orderedUuids, 600);

  // Setup the group.
  useEffect(() => {
    if (data?.reqMgt.statuses != null) {
      setGroup(
        data.reqMgt.statuses.reduce<Group>((prev, curr) => {
          prev[curr.type] = prev[curr.type] != null ? (prev[curr.type] ?? []).concat([curr]) : [curr];

          return prev;
        }, {}),
      );
    }
  }, [data?.reqMgt.statuses]);

  // Deal with the reorder.
  useEffect(() => {
    if (uuids.length > 0) {
      orderMutation({ variables: { uuids }, refetchQueries: ["Statuses"] }).catch(showError);
    }
    // Would avoid to put showError to avoid infinite update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderMutation, uuids]);

  const deleteStatus = useCallback(
    async (uuid: string) => {
      const result = await deleteMutation({ variables: { uuid }, refetchQueries: ["Statuses"] });
      if (result.data?.reqMgt.deleteStatus === true) {
        toast.neutral(t("requests.status.statusDeleted"));
      }
    },
    [deleteMutation, t, toast],
  );

  const createStatus = useCallback(
    async ({ type, name, ownerCompanyUuid }: ReqMgtCreateCategoryInput & { type: ReqMgtStatusType }) => {
      await createMutation({
        variables: {
          input: {
            type,
            name,
            ownerCompanyUuid,
          },
        },
        refetchQueries: ["Statuses"],
        update: (_cache, { data: resultData }) => {
          const newStatus = resultData?.reqMgt.createStatus;
          if (newStatus != null) {
            setGroup((curr) => ({
              ...curr,
              [newStatus.type]: (curr[newStatus.type] ?? []).concat([newStatus]),
            }));
          }
        },
      });
    },
    [createMutation],
  );

  const updateStatus = useCallback(
    async ({ type, uuid, name }: StatusFragment) => {
      await updateMutation({
        variables: {
          uuid,
          input: {
            type,
            name,
          },
        },
        refetchQueries: ["Statuses"],
      });
    },
    [updateMutation],
  );

  const statusTypes = useMemo(() => {
    return [ReqMgtStatusType.NotStarted, ReqMgtStatusType.InProgress, ReqMgtStatusType.Completed].map((type) => ({
      id: type,
      title: t(`requests.status.type.${type}`),
      count: group[type]?.length ?? undefined,
    }));
  }, [group, t]);

  const statuses = useMemo(() => {
    if (selectedType == null) {
      return [];
    }

    const target = group[selectedType];
    if (target == null) {
      return [];
    }

    return target.map((status) => ({
      uuid: status.uuid,
      title: status.name,
      type: status.type,
      ownerCompanyUuid: status.ownerCompany?.uuid ?? "",
    }));
  }, [group, selectedType]);

  const reorder = useCallback(
    (direction: "up" | "down", uuid: string) => {
      if (selectedType == null) {
        return;
      }

      const targetArray = group[selectedType];
      if (targetArray == null) {
        return;
      }

      const targetIndex = targetArray.findIndex((i) => i.uuid === uuid);
      if (
        targetIndex === -1 ||
        (targetIndex === 0 && direction === "up") ||
        (targetIndex === targetArray.length - 1 && direction === "down")
      ) {
        return;
      }

      const items = [...targetArray];
      const indexToSwap = direction === "up" ? targetIndex - 1 : targetIndex;
      items.splice(indexToSwap, 2, items[indexToSwap + 1], items[indexToSwap]);

      setGroup((curr) => ({
        ...curr,
        [selectedType]: items,
      }));

      setOrderedUuids(items.map((m) => m.uuid));
    },
    [group, selectedType],
  );

  return {
    NEW_ID,
    createLoading,
    updateLoading,
    deleteLoading,
    orderingLoading,
    mutationLoading: createLoading || updateLoading || deleteLoading || orderingLoading,
    createStatus,
    deleteStatus,
    updateStatus,
    editingStatus,
    setEditingStatus,
    selectedType,
    setSelectedType,
    statusesLoading: loading,
    statusesError: error,
    statuses,
    statusTypes,
    reorder,
  };
}
