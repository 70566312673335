import { useMemo, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";

import {
  useCategoriesQuery,
  useCategoryPresetsQuery,
  useCategoryTypesQuery,
  useCreateCategoryMutation,
  useCreateSubCategoryMutation,
  useCurrentDestinationBuildingsQuery,
  useDeleteCategoryMutation,
  useDeleteSubCategoryMutation,
  useSeedCategoryMutation,
  useSubCategoriesQuery,
  useUpdateCategoryMutation,
  useUpdateSubCategoryMutation,
} from "../../../generated/requests-client";
import CategoryTypeKey from "../runtypes/CategoryTypeKey";

export function useCategoryData() {
  const { t } = useTranslation();

  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState<string>();
  const [selectedSubCategoryUuid, setSelectedSubCategoryUuid] = useState<string>();
  const [showSubCategoryAdder, setShowSubCategoryAdder] = useState(false);
  const [editingSubCategoryUuid, setEditingSubCategoryUuid] = useState<string>();

  const { data, loading: categoriesLoading } = useCategoriesQuery({ variables: { buildingUuid: null } });
  const { data: buildingsData } = useCurrentDestinationBuildingsQuery({ fetchPolicy: "network-only" }); // always fetch up-to-date list of buildings
  const { data: typesData } = useCategoryTypesQuery();
  const { data: subCategoriesData, loading: subCategoriesLoading } = useSubCategoriesQuery({
    skip: selectedCategoryUuid == null,
    variables: { categoryUuid: selectedCategoryUuid ?? "" }, // empty string won't ever actual get sent in a request because of the skip rule
  });

  const { data: presetsData } = useCategoryPresetsQuery();

  const [createCategoryMutation, { loading: createCategoryLoading }] = useCreateCategoryMutation({
    refetchQueries: ["Categories"],
  });
  const [updateCategoryMutation, { loading: updateCategoryLoading }] = useUpdateCategoryMutation({
    refetchQueries: ["Categories", "CategoryPresets"],
  });
  const [deleteCategoryMutation] = useDeleteCategoryMutation({ refetchQueries: ["Categories", "CategoryPresets"] });
  const [seedCategoryMutation, { loading: seedCategoryLoading }] = useSeedCategoryMutation({
    refetchQueries: ["Categories", "CategoryPresets"],
  });

  const [createSubCategoryMutation, { loading: createSubCategoryLoading }] = useCreateSubCategoryMutation({
    refetchQueries: ["SubCategories"],
  });
  const [updateSubCategoryMutation, { loading: updateSubCategoryLoading }] = useUpdateSubCategoryMutation({
    refetchQueries: ["SubCategories"],
  });
  const [deleteSubCategoryMutation] = useDeleteSubCategoryMutation({ refetchQueries: ["SubCategories"] });

  const categories = data?.reqMgt.categories ?? [];
  const subCategories = subCategoriesData?.reqMgt.subCategories ?? [];
  const buildings = buildingsData?.viewer.currentDestination?.destination.buildings ?? [];
  const presets = presetsData?.reqMgt.categoryPresets ?? [];

  const categoriesMap = useMemo(
    () => new Map(data?.reqMgt.categories.map((category) => [category.uuid, category])),
    [data],
  );

  const typeOptions = useMemo(() => {
    if (typesData?.reqMgt.categoryTypes == null) {
      return [];
    }

    // only provide localised types
    return typesData.reqMgt.categoryTypes
      .filter(({ key }) => CategoryTypeKey.guard(key))
      .map(({ uuid, key }) => {
        return {
          value: uuid,
          label: t(`requests.category.types.${CategoryTypeKey.check(key)}`),
        };
      });
  }, [t, typesData]);

  return {
    categories,
    categoriesMap,
    categoriesLoading,
    setSelectedCategoryUuid,
    selectedCategoryUuid,
    setSelectedSubCategoryUuid,
    selectedSubCategoryUuid,
    createCategoryMutation,
    createCategoryLoading,
    updateCategoryMutation,
    updateCategoryLoading,
    deleteCategoryMutation,
    seedCategoryMutation,
    seedCategoryLoading,
    subCategories,
    subCategoriesLoading,
    showSubCategoryAdder,
    setShowSubCategoryAdder,
    editingSubCategoryUuid,
    setEditingSubCategoryUuid,
    createSubCategoryMutation,
    createSubCategoryLoading,
    updateSubCategoryMutation,
    updateSubCategoryLoading,
    deleteSubCategoryMutation,
    buildings,
    typeOptions,
    presets,
  };
}
