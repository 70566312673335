import React, { useContext, useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { get } from "lodash";
import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, Material } from "@equiem/react-admin-ui";

import type { CategoryPresetsQuery } from "../generated/requests-client";
import { ModalContext } from "../pages/settings/contexts/ModalContext";
import { useCategoryData } from "../pages/settings/hooks/useCategoryData";

import { BuildingsSelect } from "./BuildingsSelect";
import { CreatableSelect } from "./CreatableSelect";

export interface CategoryFormValues {
  name: string;
  iconName: string;
  type: string;
  buildings: string[];
}

export const getValidationSchema = (t: TFunction, typeRequired: boolean) =>
  yup.object().shape({
    name: yup.string().required(t("common.nameRequired")),
    iconName: yup.string().required(t("common.selectIcon")),
    type: typeRequired ? yup.string().required(t("common.selectType")) : yup.string().optional(),
    buildings: yup.array(yup.string()).min(1),
  });

type CategoryPreset = CategoryPresetsQuery["reqMgt"]["categoryPresets"][number];

interface Props {
  showTypeField: boolean;
  selectedPreset?: CategoryPreset;
  onNameChange?: (newName: string) => void;
}

export const CategoryForm: React.FC<Props> = ({ showTypeField, selectedPreset, onNameChange }) => {
  const { buildings, typeOptions, presets } = useCategoryData();
  const [selectedIcon, setSelectedIcon] = useState<string>();
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue, dirty } = useFormikContext<CategoryFormValues>();
  const modal = useContext(ModalContext);
  const isCreateModal = modal.id == null;

  useEffect(() => {
    onNameChange?.(values.name);
  }, [onNameChange, values.name]);

  useEffect(() => {
    if (isCreateModal) {
      const iconName = selectedPreset?.iconName ?? "";
      setFieldValue("iconName", iconName).catch(console.error);
    }
  }, [isCreateModal, selectedPreset?.iconName, setFieldValue]);

  const getFieldError = (field: string): string | undefined =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    get(touched, field.split(".")[0]) === true || Array.isArray(get(touched, field)) || (field === "iconName" && dirty)
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        get(errors, field)?.toString()
      : undefined;

  return (
    <>
      <Form>
        <div className="d-flex">
          <div className="flex-grow-0 iconNameGroup">
            <EqForm.Group label={t("requests.icon")} required error={getFieldError("iconName")}>
              <Material.Provider>
                <Field
                  name="iconName"
                  as={Material.Selector}
                  size="lg"
                  selectedIcon={selectedIcon ?? (values.iconName !== "" ? values.iconName : undefined)}
                  onSelect={(name: string) => setSelectedIcon(name)}
                  className="icon-type"
                  error={errors.iconName}
                />
              </Material.Provider>
            </EqForm.Group>
          </div>
          <div className="flex-grow-1 pl-5">
            <EqForm.Group label={t("requests.category.categoryName")} required error={getFieldError("name")}>
              {isCreateModal ? (
                <Field
                  name="name"
                  as={CreatableSelect}
                  items={presets.map((preset) => ({
                    value: preset.categoryName,
                  }))}
                />
              ) : (
                <Field name="name" as={EqForm.Input} />
              )}
            </EqForm.Group>
          </div>
        </div>
        {showTypeField ? (
          <div>
            <EqForm.Group label={t("requests.category.categoryTypeLabel")} required error={getFieldError("type")}>
              <Field
                name="type"
                as={EqForm.DynamicSelect}
                options={typeOptions}
                noneLabel={t("requests.category.categoryTypePlaceholder")}
              />
            </EqForm.Group>
          </div>
        ) : null}
        <div className="mt-4">
          <EqForm.Group label={t("common.buildings")} required error={getFieldError("buildings")}>
            <Field name="buildings" as={BuildingsSelect} buildings={buildings} />
          </EqForm.Group>
        </div>
      </Form>
      <style jsx>{`
        .iconNameGroup :global(p) {
          margin-right: -10rem;
          margin-bottom: -1rem;
        }
      `}</style>
    </>
  );
};
