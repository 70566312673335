import React, { useContext, useMemo, useState } from "react";

import { stringNotEmpty, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { StatusTag } from "../../../components/StatusTag";
import type { RequestQuery } from "../../../generated/requests-client";
import { ReqMgtStatusType, useStatusesQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}

const RequestStatusEdit: React.FC<P> = ({ request }) => {
  const showError = useShowError();
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState(request.status.uuid);
  const { data, loading, error } = useStatusesQuery({
    fetchPolicy: "network-only",
  });
  const { loading: updateLoading, update } = useContext(RequestDetailsContext);

  if (error != null) {
    showError(error);
  }

  const weight = useMemo(
    () => [ReqMgtStatusType.NotStarted, ReqMgtStatusType.InProgress, ReqMgtStatusType.Completed],
    [],
  );

  const options = useMemo(() => {
    return (data?.reqMgt.statuses ?? []).map((status) => ({
      label: <StatusTag name={status.name} type={status.type} />,
      value: status.uuid,
      group: { name: t(`requests.status.type.${status.type}`), weight: weight.findIndex((v) => v === status.type) },
    }));
  }, [data?.reqMgt.statuses, t, weight]);

  if (loading) {
    return <Skeleton.Line width="100px" height="20px" />;
  }

  return (
    <>
      <div>
        <Form.DynamicSelect
          mobileView="minimal"
          size="sm"
          disabled={updateLoading}
          showSelectedOnList
          name="rstatus"
          showChrome="onInteraction"
          value={selectedStatus}
          options={options}
          onChange={(event) => {
            const uuid = event.target.value;
            if (stringNotEmpty(uuid) && uuid !== selectedStatus) {
              // Update function already handle the nice catch.
              update(request, { status: uuid })
                .then(() => {
                  setSelectedStatus(uuid);
                })
                .catch(console.log);
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};

export const RequestStatus: React.FC<P> = ({ request }) => {
  return request.viewerRelations.canManage || request.viewerRelations.assignee ? (
    <RequestStatusEdit request={request} />
  ) : (
    <div className="py-3">
      <StatusTag name={request.status.name} type={request.status.type} />
    </div>
  );
};
