import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { CatalogueCalendarResourceColumn } from "./CatalogueCalendarResourceColumn";
import { CatalogueCard, CatalogueCardLoading } from "../CatalogueCard";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { type ResourceCatalogueQuery } from "../../../../generated/gateway-client";

type BookableResourcesList = ResourceCatalogueQuery["myResourceCatalogue"];

const MILLISECONDS_IN_MINUTE = 60000;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;
const LOADING_CARDS_NUM = 8;
const SCROLL_TOP_UPPER_THRESHOLD = 15;
const SCROLL_TOP_LOWER_THRESHOLD = 120;

const generateHours = (language: string): string[] => {
  const midnight = DateTime.fromFormat("0", "h");
  const formatter = Intl.DateTimeFormat(language, { hour: "numeric", timeZone: midnight.zoneName ?? undefined });
  return Array(HOURS_IN_DAY)
    .fill(null)
    .map((_, i) => formatter.format(midnight.plus({ hour: i }).toJSDate()));
};

export interface Props {
  timezone: string;
  resources: BookableResourcesList;
  resourcesLoading: boolean;
  startDate: number;
}

export const CatalogueCalendar: React.FC<Props> = ({ timezone, resources, resourcesLoading, startDate }) => {
  const { i18n } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme();
  const calendarRef = useRef<HTMLDivElement>(null);
  const spacerRef = useRef<HTMLDivElement>(null);
  const timeLineRef = useRef<HTMLDivElement>(null);
  const timeMarkerRef = useRef<HTMLDivElement>(null);
  const currentTime = DateTime.local({ zone: timezone });

  const hours = generateHours(i18n.language);
  const isToday = DateTime.fromMillis(startDate, { zone: timezone }).hasSame(currentTime, "day");

  const { createObserver, loadedResources, setLoadedResources } = useIntersectionObserver();

  useEffect(() => {
    setLoadedResources({});
  }, [setLoadedResources, startDate]);

  useEffect(() => {
    if (isToday && calendarRef.current != null) {
      const previosHour = currentTime.hour > 0 ? currentTime.minus({ hour: 1 }) : currentTime.startOf("day");
      calendarRef.current.scrollTo({
        top: previosHour.hour * MINUTES_IN_HOUR + previosHour.minute,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRef, isToday, startDate]);

  const [showThumbnails, setShowThumbnails] = useState(false);
  useEffect(() => {
    const calendarElement = calendarRef.current;

    const handleScroll = () => {
      if (calendarElement == null) {
        return;
      }

      setShowThumbnails((prev) => {
        if (prev && calendarElement.scrollTop > SCROLL_TOP_LOWER_THRESHOLD) {
          return false;
        } else if (!prev && calendarElement.scrollTop < SCROLL_TOP_UPPER_THRESHOLD) {
          return true;
        } else {
          return prev;
        }
      });
    };

    if (calendarElement != null) {
      calendarElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (calendarElement != null) {
        calendarElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const updateCurrentTimeLine = () => {
      if (
        !isToday ||
        resourcesLoading ||
        spacerRef.current == null ||
        timeLineRef.current == null ||
        timeMarkerRef.current == null
      ) {
        return;
      }

      const now = DateTime.local({ zone: timezone });
      const { width: spacerWidth, height: spacerHeight } = spacerRef.current.getBoundingClientRect();
      const leftPosition = spacerWidth - 3;
      timeMarkerRef.current.style.left = `${leftPosition}px`;
      const topPosition = now.hour * MINUTES_IN_HOUR + now.minute + spacerHeight;
      timeLineRef.current.style.top = `${topPosition}px`;
    };

    updateCurrentTimeLine();
    const intervalId = setInterval(updateCurrentTimeLine, MILLISECONDS_IN_MINUTE);

    return () => clearInterval(intervalId);
  }, [isToday, resourcesLoading, showThumbnails, timezone]);

  return (
    <div className="catalogue-calendar-wrapper" ref={calendarRef}>
      <div className="catalogue-calendar-grid">
        <div className="resource-header-spacer" ref={spacerRef}></div>
        {resourcesLoading ? (
          <>
            {Array.from({ length: LOADING_CARDS_NUM }).map((_, index) => (
              <div className="resource-header" key={index}>
                <CatalogueCardLoading size="small" showThumbnail={showThumbnails} />
              </div>
            ))}
          </>
        ) : (
          <>
            {resources.map((r) => (
              <div className="resource-header" key={r.uuid}>
                <CatalogueCard resource={r} size="small" showThumbnail={showThumbnails} />
              </div>
            ))}
          </>
        )}
        {hours.map((hour, rowIndex) => (
          <React.Fragment key={hour}>
            <div className="time-cell">{rowIndex !== 0 && <span>{hour}</span>}</div>
            {rowIndex === 0 && (
              <>
                {resourcesLoading ? (
                  <>
                    {Array.from({ length: LOADING_CARDS_NUM }).map((_, colIndex) => (
                      <div key={`cell-${rowIndex}-${colIndex}`} className="resource-cell resource-column"></div>
                    ))}
                  </>
                ) : (
                  <>
                    {resources.map((resource) => (
                      <div
                        key={`cell-${rowIndex}-${resource.uuid}`}
                        className="resource-cell resource-column"
                        ref={createObserver(resource.uuid)}
                      >
                        <CatalogueCalendarResourceColumn
                          siteTimezone={timezone}
                          resource={resource}
                          start={startDate}
                          end={DateTime.fromMillis(startDate, { zone: timezone }).endOf("day").toMillis()}
                          isLoaded={Boolean(loadedResources[resource.uuid])}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </React.Fragment>
        ))}
        {isToday && !resourcesLoading && (
          <div ref={timeLineRef} className="current-time-line">
            <div ref={timeMarkerRef} className="current-time-circle"></div>
          </div>
        )}
      </div>
      <style jsx>{`
        .catalogue-calendar-wrapper {
          margin: ${spacers.s4} ${spacers.s5} ${spacers.s7};
          overflow-x: auto;
          max-height: calc(100vh - 120px);
          max-width: calc(100% - ${spacers.s5} * 2);
          width: fit-content;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .catalogue-calendar-wrapper {
            margin: ${spacers.s0} ${spacers.s2};
            max-width: calc(100% - ${spacers.s2} * 2);
          }
        }
        .catalogue-calendar-grid {
          position: relative;
          width: fit-content;
          display: grid;
          grid-template-columns: 60px repeat(${resourcesLoading ? LOADING_CARDS_NUM : resources.length}, 1fr);
        }
        .current-time-line {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #008000;
          z-index: 1;
        }
        .current-time-circle {
          position: absolute;
          width: 6px;
          height: 6px;
          top: -3px;
          border-radius: 50%;
          background-color: #008000;
        }
        .resource-header,
        .resource-cell {
          border-right: 1px solid ${colors.border};
          border-bottom: 1px solid ${colors.border};
        }
        .resource-header-spacer,
        .resource-header {
          position: sticky;
          top: 0;
          background: ${colors.white};
          padding: ${spacers.s0} ${spacers.s4} ${spacers.s3};
        }
        .resource-header-spacer {
          z-index: 4;
        }
        .resource-header {
          z-index: 2;
        }
        .time-cell {
          position: relative;
          padding: ${spacers.s3};
          white-space: nowrap;
          height: 60px;
          font-size: 12px;
          color: ${colors.grayscale[60]};

          position: sticky;
          z-index: 3;
        }
        .resource-header-spacer,
        .time-cell {
          border-right: 1px solid ${colors.border};
          width: 60px;
          left: 0;
          background: ${colors.white};
        }
        .time-cell span {
          position: absolute;
          display: block;
          top: -0.5em;
          right: ${spacers.s3};
        }
        .resource-column {
          grid-row: span ${HOURS_IN_DAY};
          display: grid;
          grid-template-rows: repeat(${MINUTES_IN_DAY}, 1px);
          grid-template-columns: repeat(6, 34px);
          border: 1px solid ${colors.border};
          border-width: 0 1px 1px 0;
          background-image: linear-gradient(${colors.grayscale[5]} 1px, transparent 1px);
          background-size: 1px 60px;
        }
        .resource-column :global(.time-slot) {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 12px;
        }
        .resource-column :global(.inactive-time-slot) {
          grid-column-start: 1;
          grid-column-end: 7;
          cursor: not-allowed;
          border-radius: 2px;
          margin: ${spacers.s1};
        }
        .resource-column :global(.loading-hourly-time-range) {
          background: linear-gradient(to right, #f5f5f5 0%, #efefef 15%, #f5f5f5 30%);
          background-size: 1200px 100%;
          animation: skeletonAnimation 3s linear infinite;
        }
        .resource-column :global(.unavailable-time-range) {
          background: ${colors.grayscale[10]};
          opacity: 0.5;
        }
        .resource-column :global(.taken-time-slot) {
          background: #e6000e1a;
        }
        .resource-column :global(.active-time-slot) {
          cursor: pointer;
        }
        .resource-column :global(.own-time-slot) {
          grid-column-start: 1;
          grid-column-end: 7;

          background: #7b7bff;

          color: ${colors.white};
          font-weight: 600;

          border-radius: 2px;
          border-left: 2px solid #3030fc;
          margin: ${spacers.s1};
        }
        .resource-column :global(.available-time-range) {
          grid-column-start: 1;
          grid-column-end: 7;
        }
        .resource-column :global(.available-time-slot) {
          background: ${colors.white};

          color: ${colors.grayscale[60]};
          font-weight: 400;

          border: 1px solid ${colors.border};

          z-index: 1;
        }
        .resource-column :global(.available-time-slot:hover),
        .resource-column :global(.available-time-range:hover) {
          background: #eaeaff;
        }
        @keyframes skeletonAnimation {
          0% {
            background-position: -1200px 0%;
          }
          100% {
            background-position: 1200px 0%;
          }
        }
      `}</style>
    </div>
  );
};
