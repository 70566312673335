import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { ApolloError } from "@apollo/client";
import type { FormikProps } from "formik";
import { Formik } from "formik";

import { useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Modal, Tabs, useConfirmer, useIsMobileWidth, useTheme, useToast } from "@equiem/react-admin-ui";

import type { CategoryPresetsQuery } from "../generated/requests-client";
import { ModalContext } from "../pages/settings/contexts/ModalContext";
import { useCategoryData } from "../pages/settings/hooks/useCategoryData";

import { CategoryCafmIntegrationModalContext } from "./category-cafm-integration/CategoryCafmIntegrationModalContext";
import type { CategoryTabKey } from "./utils/editCategoryTabs";
import { editCategoryTabs } from "./utils/editCategoryTabs";
import type { CategoryFormValues } from "./CategoryForm";
import { CategoryForm, getValidationSchema } from "./CategoryForm";
import { CategoryIntegrationsForm } from "./CategoryIntegrationsForm";

type CategoryPreset = CategoryPresetsQuery["reqMgt"]["categoryPresets"][number];

export const CategoryModal: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();
  const { uuid: siteUuid } = useSiteContext();
  const {
    setSelectedCategoryUuid,
    createCategoryMutation,
    updateCategoryMutation,
    seedCategoryMutation,
    createCategoryLoading,
    updateCategoryLoading,
    categoriesMap,
    presets,
  } = useCategoryData();
  const toast = useToast();
  const showError = useShowError();
  const formInnerRef = useRef<FormikProps<CategoryFormValues>>(null);
  const { withConfirmation } = useConfirmer();
  const [showModal, setShowModal] = useState(false);
  const { colors, spacers } = useTheme();
  const cafmLinkModalContext = useContext(CategoryCafmIntegrationModalContext);

  const tabs = editCategoryTabs(t);
  const [selectedTab, setSelectedTab] = useState<CategoryTabKey>();
  useEffect(() => {
    setSelectedTab(tabs[0].key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const modal = useContext(ModalContext);
  const isUpdateModal = modal.id != null;
  const category = useMemo(() => (modal.id != null ? categoriesMap.get(modal.id) : undefined), [modal, categoriesMap]);

  const [selectedPreset, setSelectedPreset] = useState<CategoryPreset>();
  const showTypeField = !isUpdateModal && selectedPreset == null;

  const handleNameChange = (newName: string) => {
    const preset = presets.find(({ categoryName }) => categoryName.toLowerCase() === newName.trim().toLowerCase());
    setSelectedPreset(preset);
  };

  const currentTab = useMemo(() => {
    switch (selectedTab) {
      case "integrations":
        return cafmLinkModalContext.configurations != null &&
          cafmLinkModalContext.configurations.reqMgt.cafmConfigurationsBySiteUuid.length > 0 ? (
          <CategoryIntegrationsForm />
        ) : null;
      default:
        return (
          <CategoryForm showTypeField={showTypeField} selectedPreset={selectedPreset} onNameChange={handleNameChange} />
        );
    }
  }, [category, selectedTab, selectedPreset, showModal]);

  useEffect(() => {
    if (modal.activeModal === "CategoryModal") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modal.activeModal]);

  const onClose = useCallback(() => {
    setSelectedPreset(undefined);
    setShowModal(false);
    modal.close();
  }, [setShowModal, setSelectedPreset, modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("common.cancelMessage"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose, formInnerRef]);

  return (
    <>
      <Modal.Dialog
        title={isUpdateModal ? t("requests.category.edit") : t("common.createNew")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header
          intro={isUpdateModal ? category?.name : t("requests.category.addNewCategory")}
          closeButton
          noBorder={!isMobile}
          onClose={onCloseModal}
          supportsMobile
        />
        <Formik
          enableReinitialize
          initialValues={
            modal.id == null
              ? { name: "", iconName: "", type: "", buildings: [] }
              : {
                  name: category?.name ?? "",
                  iconName: category?.iconName ?? "",
                  type: category?.type ?? "",
                  buildings: category?.buildings.map((building) => building?.uuid ?? "") ?? [],
                }
          }
          innerRef={formInnerRef}
          validationSchema={getValidationSchema(t, showTypeField)}
          onSubmit={async ({ name, iconName, type, buildings }, { setFieldError }) => {
            try {
              if (isUpdateModal) {
                if (category?.uuid == null) {
                  toast.negative(t("common.changesSaveFailed"));
                  console.error("Category UUID missing");
                  return;
                }

                await updateCategoryMutation({
                  variables: {
                    uuid: category.uuid,
                    name,
                    iconName,
                    siteUuid,
                    buildingUuids: buildings,
                    presetUuid: name === category.name ? category.presetUuid : null,
                  },
                });
                toast.neutral(t("common.changesSaved"));
              } else if (selectedPreset != null) {
                const { data } = await seedCategoryMutation({
                  variables: { uuid: selectedPreset.uuid, iconName, siteUuid, buildingUuids: buildings },
                });
                if (data?.reqMgt.seedCategory.uuid != null) {
                  setSelectedCategoryUuid(data.reqMgt.seedCategory.uuid);
                }
                toast.positive(t("requests.category.newCategoryAdded"));
              } else {
                const { data } = await createCategoryMutation({
                  variables: { name, type, iconName, siteUuid, buildingUuids: buildings },
                });
                if (data?.reqMgt.createCategory.uuid != null) {
                  setSelectedCategoryUuid(data.reqMgt.createCategory.uuid);
                }
                toast.positive(t("requests.category.newCategoryAdded"));
              }
              onClose();
            } catch (e: unknown) {
              if (
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (e as ApolloError)?.graphQLErrors?.some((gqlError) => gqlError?.extensions?.code === "ALREADY_EXISTS")
              ) {
                console.error(e);
                setFieldError("name", t("requests.category.alreadyExists"));
                return;
              }

              showError(e);
            }
          }}
        >
          {({ submitForm, dirty, isValid, isSubmitting }) => (
            <>
              <div className="px-6 mobile-header">
                <Tabs
                  items={isUpdateModal ? tabs : tabs.filter((x) => x.key === "general")}
                  selected={selectedTab ?? tabs[0].key}
                  onSelect={(key) => {
                    setSelectedTab(key as CategoryTabKey);
                  }}
                />
              </div>
              <Modal.Body>
                <div className="content">{currentTab}</div>
              </Modal.Body>
              {selectedTab === "general" && (
                <Modal.Footer>
                  <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                    {t("common.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={!dirty || !isValid || isSubmitting || createCategoryLoading || updateCategoryLoading}
                    onClick={() => {
                      submitForm().catch(showError);
                    }}
                  >
                    {isUpdateModal ? t("common.saveChanges") : t("requests.category.addCategory")}
                  </Button>
                </Modal.Footer>
              )}
            </>
          )}
        </Formik>
      </Modal.Dialog>
      <style jsx>{`
        .mobile-header {
          border-top: 1px solid ${colors.grayscale[10]};
          border-bottom: 1px solid ${colors.grayscale[10]};
          position: sticky;
          top: 64px;
          z-index: 2;
        }
        .content {
          padding-top: ${spacers.s5};
        }
      `}</style>
    </>
  );
};
