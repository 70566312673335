import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Field, useFormikContext } from "formik";

import { Site, stringIsEmpty, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, Modal, useToast } from "@equiem/react-admin-ui";

import type {
  CategoryCafmConfigurationByUuidQuery,
  CategoryCafmConfigurationByUuidQueryVariables,
  CategoryCafmConfigurationsByCategoryUuidQuery,
} from "../../generated/requests-client";
import {
  CategoryCafmConfigurationByUuidDocument,
  CategoryCafmConfigurationsByCategoryUuidDocument,
  useUpsertCategoryCafmConfigurationMutation,
} from "../../generated/requests-client";
import { ModalContext } from "../../pages/settings/contexts/ModalContext";
import { useCategoryData } from "../../pages/settings/hooks/useCategoryData";
import type { CategoryFormValues } from "../CategoryForm";

import { CategoryCafmIntegrationModalContext } from "./CategoryCafmIntegrationModalContext";

interface Props {
  configurationLinks: CategoryCafmConfigurationsByCategoryUuidQuery | undefined;
}

export const CategoryCafmIntegrationWidget: React.FC<Props> = ({ configurationLinks }) => {
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(CategoryCafmIntegrationModalContext);
  const { t } = useTranslation();
  const categoryModal = useContext(ModalContext);
  const { uuid: siteUuid } = useContext(Site);
  const { values } = useFormikContext<CategoryFormValues>();
  const { buildings: allBuildings } = useCategoryData();
  const client = useApolloClient();
  const toast = useToast();
  const showError = useShowError();
  const [upsert, { loading: upsertLoading }] = useUpsertCategoryCafmConfigurationMutation();
  const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
  const [cafmProviderName, setCafmProviderName] = useState<string>("");
  const [selectedCafmConfiguration, setSelectedCafmConfiguration] = useState<string>("");
  const [isLinkLoading, setIsLinkLoading] = useState<boolean>(false);
  const isCreateModal = modal.id == null;

  const isLoading = modal.loading || upsertLoading || isLinkLoading;
  const cafmConfigurationOpts = useMemo(() => {
    return (
      modal.configurations?.reqMgt.cafmConfigurationsBySiteUuid.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })) ?? []
    );
  }, [modal.configurations]);

  const filterBuildings = (buildingUuid: string, linkedBuildings: string[]) => {
    // building is in collection returned by overarching modal context
    return allBuildings.find((b) => b.uuid === buildingUuid) != null &&
      // && it's not yet been linked to any cafm provider configuration avaliable for the site (per category)
      // does not apply for editing mode
      isCreateModal
      ? linkedBuildings.find((lbUuid) => lbUuid === buildingUuid) == null
      : true;
  };

  const buildingOptions = useMemo(() => {
    const linkedBuildings = [
      ...new Set(
        configurationLinks?.reqMgt.categoryCafmConfigurationsByCategoryUuid
          .map((link) => link.buildings.map((lb) => lb.uuid))
          .flat() ?? [],
      ),
    ];

    const filteredBuildings =
      values.buildings
        .filter((buildingUuid) => filterBuildings(buildingUuid, linkedBuildings))
        .map((buildingUuid) => ({
          label: allBuildings.find((b) => b.uuid === buildingUuid)?.name ?? "Error",
          value: buildingUuid,
        })) ?? [];

    return filteredBuildings;
  }, [allBuildings, configurationLinks, modal.id]);

  const clear = () => {
    setSelectedBuildings([]);
    setCafmProviderName("");
    setSelectedCafmConfiguration("");
    setIsLinkLoading(false);
  };

  useEffect(() => {
    if (modal.id != null) {
      void client
        .query<CategoryCafmConfigurationByUuidQuery, CategoryCafmConfigurationByUuidQueryVariables>({
          query: CategoryCafmConfigurationByUuidDocument,
          variables: {
            uuid: modal.id,
          },
        })
        .then((result) => {
          setSelectedBuildings(result.data.reqMgt.categoryCafmConfiguration.buildings.map((x) => x.uuid));
          setCafmProviderName(result.data.reqMgt.categoryCafmConfiguration.cafmCategoryName ?? "");
          setSelectedCafmConfiguration(result.data.reqMgt.categoryCafmConfiguration.cafmConfiguration.uuid);
        })
        .catch(showError);
    } else {
      clear();
    }
  }, [modal.id]);

  const isValid = !stringIsEmpty(selectedCafmConfiguration) && selectedBuildings.length > 0;

  const onClose = useCallback(() => {
    clear();
    modal.close();
    setShowModal(false);
  }, [modal]);

  useEffect(() => {
    setShowModal(modal.activeModal === "CafmIntegrationModal");
  }, [modal.activeModal, setShowModal]);

  const confirmCancel = useCallback(
    (onConfirm?: () => void) => {
      onConfirm?.();
      onClose();
    },
    [onClose],
  );

  const onCafmConfigurationChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCafmConfiguration(e.target.value);
  };

  const onAddClicked = () => {
    upsert({
      variables: {
        input: {
          uuid: modal.id,
          buildingUuids: selectedBuildings,
          categoryUuid: categoryModal.id!,
          siteUuid: siteUuid,
          providerCategoryName: stringIsEmpty(cafmProviderName) ? undefined : cafmProviderName,
          cafmConfigurationUuid: selectedCafmConfiguration,
        },
      },
      refetchQueries: [
        {
          query: CategoryCafmConfigurationsByCategoryUuidDocument,
          variables: {
            categoryUuid: categoryModal.id!,
          },
        },
      ],
    })
      .then(() => {
        toast.positive(
          isCreateModal
            ? t("requests.category.integrations.createSucceseful")
            : t("requests.category.integrations.updateSucceseful"),
        );
        onClose();
      })
      .catch(showError);
  };

  return (
    <>
      <Modal.Dialog
        title={
          isCreateModal
            ? t("requests.category.integrations.addIntegration")
            : t("requests.category.integrations.editIntegration")
        }
        show={showModal}
        scrollable={true}
        centered={true}
        onHide={() => confirmCancel()}
        hideOnEsc={true}
        hideOnClick={false}
        focusTrapOptions={{ allowOutsideClick: () => true }}
        size="md"
      >
        <Modal.Header
          closeButton={true}
          noBorder={true}
          intro={isCreateModal ? t("requests.category.integrations.add") : t("requests.category.integrations.edit")}
        />
        <Modal.Body>
          <div className="flex-grow-1">
            <EqForm.Group label={t("requests.category.integrations.cafmProvider")} required>
              <EqForm.Select
                required={true}
                onChange={onCafmConfigurationChanged}
                disabled={isLoading}
                value={selectedCafmConfiguration}
              >
                <option value="">{t("common.pleaseSelect")}</option>
                {cafmConfigurationOpts.map((c) => (
                  <option key={c.value} value={c.value}>
                    {c.label}
                  </option>
                ))}
              </EqForm.Select>
            </EqForm.Group>
          </div>
          <div className="flex-grow-1">
            <EqForm.Group label={t("requests.category.integrations.providerCategoryName")}>
              <Field
                name="cafmProviderName"
                disabled={isLoading}
                value={cafmProviderName}
                as={EqForm.Input}
                place
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setCafmProviderName(e.target.value)
                }
              />
            </EqForm.Group>
          </div>
          <div className="mt-4">
            <EqForm.Group label={t("common.buildings")} required>
              <EqForm.MultiSelect
                enableSelectAll
                variant="wrap"
                name={"cafmCategoryBuildings"}
                className="buildings-select"
                options={buildingOptions}
                disabled={isLoading}
                value={selectedBuildings}
                onChange={(e) => {
                  setSelectedBuildings(e.target.value);
                }}
                searchPlaceholder={t("requests.category.buildingsSearchPlaceholder")}
              />
            </EqForm.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="md" variant="ghost" className="mr-3" onClick={onClose} disabled={isLoading}>
            {t("common.cancel")}
          </Button>
          <Button variant="danger" size="md" disabled={isLoading || !isValid} onClick={onAddClicked}>
            {isCreateModal ? t("common.add") : t("common.saveChanges")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </>
  );
};
