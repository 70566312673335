import { useDebouncedLazyQuery, useSiteContext, notNullOrUndefined, CurrentProfile } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form, useTheme } from "@equiem/react-admin-ui";
import { Field, useFormikContext } from "formik";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import type { FieldProps } from "formik";
import { ResourceDivider } from "../ResourceDivider";
import {
  useResourceCatalogueQuery,
  useSiteCompaniesQuery,
  useValidateExternalSyncCalendarUrlLazyQuery,
} from "../../../../../generated/gateway-client";
import type { FormValues } from "../../../../../lib/formValidation";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

const ListParents: React.FC<{ names: string[] }> = ({ names }) => {
  return (
    <>
      {names.slice(0, -1).map((name, i) => (
        <>
          <span key={i} className="font-weight-bold">
            {name}
          </span>
          {names.length > 2 && ", "}
        </>
      ))}
      {names.length > 1 && " and "}
      <span className="font-weight-bold">{names[names.length - 1]}</span>
    </>
  );
};

export const ResourceCreateAndEditFormAdditionalSettings: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const { colors, spacers } = useTheme(true);
  const router = useRouter();
  const fm = useFormikContext<FormValues>();
  const site = useSiteContext();
  const profile = useContext(CurrentProfile);
  const { data: companiesData, loading: companiesLoading } = useSiteCompaniesQuery({
    variables: { destinationUuid: site.uuid },
    skip: !profile.canManageRegion,
  });

  const [validateIcsUrl] = useDebouncedLazyQuery(useValidateExternalSyncCalendarUrlLazyQuery);

  const companies = (companiesData?.destination.companiesV2?.edges ?? [])
    .map((edge) => edge.node)
    .filter(notNullOrUndefined);

  const {
    data: dataBookableResourcesList,
    loading: loadingBookableResourcesList,
    error: errorBookableResourcesList,
  } = useResourceCatalogueQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: { siteUuids: [site.uuid], excludeParentResources: true },
      permissionFilters: { canManageBookings: true },
    },
  });

  if (errorBookableResourcesList != null) {
    return <div>{tError(errorBookableResourcesList)}</div>;
  }

  const thisResourceUuid = router.query.uuid as string;
  const options = (dataBookableResourcesList?.myResourceCatalogue ?? [])
    .filter((resource) => resource.uuid !== thisResourceUuid)
    .map((resource) => ({ value: resource.uuid, label: resource.name }));

  const parents = fm.values.parentNames;

  const validate = async (url: string | null | undefined): Promise<string | undefined> => {
    if (url == null || url.length === 0) {
      return undefined;
    }

    try {
      // eslint-disable-next-line no-new
      new URL(url);
    } catch {
      return t("bookings.resources.icsMustBeAValidUrl");
    }

    try {
      const { data: dataValidateIcsUrl, error: errorValidateIcsUrl } = await validateIcsUrl({
        variables: { externalSyncCalendarUrl: url },
      });
      const icsUrlIsValid = errorValidateIcsUrl == null && dataValidateIcsUrl?.validateExternalSyncCalendarUrl === true;
      return icsUrlIsValid ? undefined : t("bookings.resources.icsLinkCouldntConnect");
    } catch (e: unknown) {
      console.error(e);
      return t("bookings.resources.failedToValidateIcsLink");
    }
  };

  const autoApproveBookingsDisabled = fm.values.autoApproveBookings === false;

  return (
    <>
      {process.env.bookingRecurringEnabled === "true" && (
        <Form.Group
          label={t("bookings.resources.enableRecurringBooking")}
          error={fm.errors.allowRecurringBooking}
          tooltipText={t("bookings.resources.allowRecurringBookingTooltip")}
          showTooltip
        >
          <Form.Checkbox
            disabled={autoApproveBookingsDisabled}
            label={t("bookings.resources.allowRecurringBooking")}
            id="allowRecurringBooking"
            name="allowRecurringBooking"
            value={fm.values.allowRecurringBooking ?? false}
            onChange={(e) => {
              fm.setFieldValue("allowRecurringBooking", e.target.checked).catch(console.error);
            }}
          />
          {autoApproveBookingsDisabled && (
            <Alert
              className="mt-4"
              size="large"
              variant="gray"
              icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
              message={t("bookings.resources.allowRecurringBookingWarning")}
            />
          )}
        </Form.Group>
      )}

      <Form.Group
        label={t("bookings.resources.createdBy")}
        placeholder={t("bookings.resources.selectCompany")}
        showTooltip
        tooltipText={t("bookings.resources.createdByHint")}
        error={fm.errors.ownerCompanyUuid}
      >
        <Field name="ownerCompanyUuid">
          {({ field }: FieldProps<string, FormValues>) => (
            <Form.Select {...field} disabled={!profile.canManageRegion || companiesLoading || fm.isSubmitting}>
              <option disabled value="">
                {t("bookings.resources.selectCompany")}
              </option>
              {profile.canManageRegion ? (
                companies.map((company) => (
                  <option key={company.uuid} value={company.uuid}>
                    {company.name}
                  </option>
                ))
              ) : (
                <option value={fm.values.ownerCompanyUuid}>{fm.values.ownerCompanyName}</option>
              )}
            </Form.Select>
          )}
        </Field>
      </Form.Group>

      <ResourceDivider />

      <div className="children-container">
        <Form.Group
          label={t("bookings.resources.selectDependentResources")}
          showTooltip
          tooltipText={t("bookings.resources.resourceDependentsHint")}
        >
          {parents.length === 0 ? (
            <Field
              name="children"
              options={options}
              as={Form.MultiSelect}
              variant="wrap"
              placeholder={t("bookings.resources.searchForResources")}
              searchPlaceholder={t("bookings.resources.resourceName")}
              isMulti
              disabled={loadingBookableResourcesList || fm.isSubmitting}
            />
          ) : (
            <div>
              {t("bookings.resources.dependentsConflict")} <ListParents names={parents} />
            </div>
          )}
        </Form.Group>
      </div>

      <ResourceDivider />

      <Form.Group
        label={t("bookings.resources.icsLink")}
        showTooltip
        tooltipText={t("bookings.resources.icsLinkHint")}
        error={fm.errors.externalSyncCalendarUrl}
      >
        <Field
          id="externalSyncCalendarUrl"
          name="externalSyncCalendarUrl"
          placeholder={t("bookings.resources.enterCalendarIcsUrl")}
          as={Form.Input}
          disabled={fm.isSubmitting}
          validate={validate}
        />
        <div className="description">{t("bookings.resources.enterCalendarIcsUrlHint")}</div>
      </Form.Group>

      <Form.Group
        label={t("bookings.resources.calendarEmailAddress")}
        showTooltip
        tooltipText={t("bookings.resources.calendarEmailAddressTooltip")}
        error={fm.errors.externalSyncEmailAddress}
      >
        <Field
          id="externalSyncEmailAddress"
          name="externalSyncEmailAddress"
          placeholder={t("bookings.resources.calendarEmailAddressHint")}
          as={Form.Input}
          disabled={fm.isSubmitting}
        />
      </Form.Group>

      <style jsx>{`
        .children-container :global(section) {
          z-index: 9999;
        }
        .children-container :global(.form-multi-select) {
          padding: ${spacers.s3} ${spacers.s5};
        }
        .children-container :global(.tags-container) {
          font-size: 14px;
          line-height: 22px;
        }
        .description {
          font-size: 12px;
          line-height: 16px;
          margin-top: ${spacers.s2};
          color: ${colors.medium};
        }
      `}</style>
    </>
  );
};
