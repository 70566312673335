import React, { useCallback, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DateTime } from "luxon";

import { CurrentProfile, CurrentRole, notNullOrUndefined, Role, useSiteContext } from "@equiem/lib";
import { ProgressCircle, useIsMobileWidth } from "@equiem/react-admin-ui";

import type { VisitorAppointment, VisitorAppointmentsSortInput } from "../../generated/visitors-client";
import { useVisitorAppointmentsQuery, useVisitorManagementSiteSettingsQuery } from "../../generated/visitors-client";
import { useVisitorEnabled } from "../../hooks/useVisitorEnabled";

import { AppointmentsFilters } from "./components/AppointmentsFilters";
import { AppointmentsTable } from "./components/AppointmentsTable";
import { EmptyList } from "./components/EmptyList";
import { EmptyListEndUser } from "./components/EmptyListEndUser";
import { AppointmentsFilterContext } from "./contexts/AppointmentsFilterContext";
import { useSorting } from "./hooks/useSorting";

const ITEMS_PER_REQUEST = 100;

export const VisitorAppointmentsTableContainer = () => {
  const isSmallMobile = useIsMobileWidth("sm");
  const { sortDirection, handleSort, sort } = useSorting();
  const { visitorAppointmentsFilters } = useContext(AppointmentsFilterContext);
  const { uuid: siteUuid } = useSiteContext();
  const { profile } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const showVisitorManagement = useVisitorEnabled();
  const isEndUserViewReadonly =
    currentRole === Role.Unknown && showVisitorManagement && profile?.isAppointmentOrganizer !== true;

  const variables = {
    beginDate: DateTime.local().startOf("day").toMillis(),
    sort: sort(sortDirection),
    ...visitorAppointmentsFilters,
    first: ITEMS_PER_REQUEST,
  };

  const {
    data: appointmentsData,
    fetchMore,
    refetch,
    loading: initialLoading,
  } = useVisitorAppointmentsQuery({
    variables,
  });

  const { data: vmSiteSettings, loading: vmSiteSettingsLoading } = useVisitorManagementSiteSettingsQuery({
    variables: { siteUuid },
    fetchPolicy: "network-only",
  });

  const appointments = appointmentsData?.visitorAppointments.edges.map((e) => e.node).filter(notNullOrUndefined) ?? [];

  const hasMoreAppointments = appointmentsData?.visitorAppointments.pageInfo.hasNextPage ?? false;

  const handleNextPage = useCallback(() => {
    setFetchMoreLoading(true);
    fetchMore({
      variables: {
        after: appointmentsData?.visitorAppointments.pageInfo.endCursor,
      },
    }).finally(() => {
      setFetchMoreLoading(false);
    });
  }, [fetchMore, appointmentsData?.visitorAppointments.pageInfo.endCursor]);

  useEffect(() => {
    setRefetchLoading(true);
    refetch(variables).finally(() => {
      setRefetchLoading(false);
    });
  }, [
    refetch,
    setRefetchLoading,
    variables.as,
    variables.beginDate,
    variables.isRecurring,
    variables.endDate,
    variables.search,
    variables.buildingUuids,
    variables.hostCompanyUuids,
    variables.visitorTypeUuids,
    (variables.sort as VisitorAppointmentsSortInput[])[0].asc,
  ]);

  return (
    <div>
      <InfiniteScroll
        next={handleNextPage}
        dataLength={appointments.length}
        hasMore={hasMoreAppointments}
        className={isSmallMobile ? "p-5" : "p-7"}
        style={{ overflow: undefined }}
        loader={
          <>
            {fetchMoreLoading && (
              <div className="mt-6 mb-6 d-flex justify-content-center">
                <ProgressCircle size="md" />
              </div>
            )}
          </>
        }
      >
        <AppointmentsFilters variables={variables} />
        {((initialLoading || vmSiteSettingsLoading) && appointments.length === 0) || refetchLoading ? (
          <>
            <div className="mt-6 mb-6 d-flex justify-content-center">
              <ProgressCircle size="md" />
            </div>
          </>
        ) : (
          <>
            {appointments.length === 0 ? (
              <>
                {isEndUserViewReadonly ? (
                  <EmptyListEndUser />
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <EmptyList />
                  </div>
                )}
              </>
            ) : (
              <>
                <AppointmentsTable
                  vmSiteSettings={vmSiteSettings}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                  appointments={appointments as VisitorAppointment[]}
                />
              </>
            )}
          </>
        )}
      </InfiniteScroll>
    </div>
  );
};
