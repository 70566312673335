import React, { useContext } from "react";
import { Form } from "formik";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ButtonLink, ColumnView, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiAddLine, RiDeleteBinLine, RiEditLine } from "@equiem/react-admin-ui/icons";

import {
  CategoryCafmConfigurationsByCategoryUuidDocument,
  useCategoryCafmConfigurationsByCategoryUuidQuery,
  useDeleteCategoryCafmConfigurationMutation,
} from "../generated/requests-client";
import { ModalContext } from "../pages/settings/contexts/ModalContext";
import { useCategoryData } from "../pages/settings/hooks/useCategoryData";

import { CategoryCafmIntegrationModalContext } from "./category-cafm-integration/CategoryCafmIntegrationModalContext";
import { CategoryCafmIntegrationWidget } from "./category-cafm-integration/CategoryCafmIntegrationWidget";

export const CategoryIntegrationsForm: React.FC = () => {
  const { t } = useTranslation();
  const categoryModal = useContext(ModalContext);
  const { colors } = useTheme();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const showError = useShowError();
  const modal = useContext(CategoryCafmIntegrationModalContext);
  const { buildings: allBuildings } = useCategoryData();
  const { data, loading } = useCategoryCafmConfigurationsByCategoryUuidQuery({
    variables: {
      categoryUuid: categoryModal.id!,
    },
  });

  const [deleteCategoryCafmConfiguration] = useDeleteCategoryCafmConfigurationMutation({
    refetchQueries: [
      {
        query: CategoryCafmConfigurationsByCategoryUuidDocument,
        variables: {
          categoryUuid: categoryModal.id!,
        },
      },
    ],
  });

  const onClickCategoryModal = (linkId?: string) => {
    modal.open("CafmIntegrationModal", linkId);
  };

  return (
    <>
      <Form>
        <CategoryCafmIntegrationWidget configurationLinks={data} />
        {data?.reqMgt.categoryCafmConfigurationsByCategoryUuid != null &&
          data.reqMgt.categoryCafmConfigurationsByCategoryUuid.length > 0 && (
            <ColumnView.Container>
              {data.reqMgt.categoryCafmConfigurationsByCategoryUuid.map((link) => (
                <ColumnView.Item
                  value={link.cafmConfiguration.type}
                  key={link.uuid}
                  maxIcons={2}
                  className="pt-2 pb-2"
                  valueTags={link.buildings.map(
                    (lb) => allBuildings.find((ab) => ab.uuid === lb.uuid)?.name ?? "Error",
                  )}
                  actions={[
                    {
                      title: t("common.edit"),
                      icon: RiEditLine,
                      onClick: () => {
                        onClickCategoryModal(link.uuid);
                      },
                    },
                    {
                      title: t("common.delete"),
                      icon: RiDeleteBinLine,
                      color: colors.status.danger.primary,
                      onClick: withConfirmation({
                        onConfirm: () => {
                          deleteCategoryCafmConfiguration({ variables: { uuid: link.uuid } })
                            .then(() => {
                              toast.neutral(t("requests.category.integrations.delSucceseful"));
                            })
                            .catch(showError);
                        },
                        title: t("common.areYouSure"),
                        message: t("requests.category.integrations.confirmDel"),
                        confirmButtonText: t("common.yesDelete"),
                        confirmButtonVariant: "danger",
                      }),
                    },
                  ]}
                />
              ))}
            </ColumnView.Container>
          )}
        <div className="flex-grow-1">
          <ButtonLink
            variant="secondary"
            size="md"
            className="mt-5"
            disabled={loading}
            onClick={() => onClickCategoryModal()}
          >
            <RiAddLine size={16} />
            {t("requests.category.integrations.add")}
          </ButtonLink>
        </div>
      </Form>
      <style jsx>{`
        .iconNameGroup :global(p) {
          margin-right: -10rem;
          margin-bottom: -1rem;
        }
      `}</style>
    </>
  );
};
