import { Calendar as CalendarView, Text, useDebounced, useTheme } from "@equiem/react-admin-ui";
import { DateTime, Interval } from "luxon";
import React, { useCallback, useContext } from "react";
import { useBookableResourceAvailabilityCalendarLazyQuery } from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingCalendarContext } from "../contexts/BookingCalendarContext";
import { dateFormat } from "../libs/formatSelectedTime";
import { BookingModal } from "../contexts/BookingModalContext";

const debounceTimeout = 500;

interface P {
  className?: string;
}
export const BookingCalendar: React.FC<P> = ({ className = "" }) => {
  const { t } = useTranslation();
  const theme = useTheme(true);
  const modal = useContext(BookingModal);
  const { resource, booking, timezone } = useContext(BookingModalInfo);
  const { selectedDate, setSelectedDate, selectedTime } = useContext(BookingCalendarContext);

  const selectedTimeDebounced = useDebounced(selectedTime, debounceTimeout);
  const target = DateTime.fromFormat(selectedDate.date, dateFormat, { zone: timezone });
  const [timesQuery] = useBookableResourceAvailabilityCalendarLazyQuery({ fetchPolicy: "network-only" });

  const getEventsCb = useCallback(
    async (originalTimeOfTheWeek: DateTime) => {
      const start = originalTimeOfTheWeek.startOf("week").toMillis();
      const end = originalTimeOfTheWeek.endOf("week").toMillis();

      try {
        const { data } = await timesQuery({
          variables: {
            uuid: resource.uuid,
            start,
            end,
            excludeBooking: booking?.uuid,
          },
        });

        return (data?.bookableResource.availabilityCalendar ?? [])
          .filter((tt) => tt.__typename === "BookableResourceCalendarTaken")
          .flatMap((tt) => {
            const ttStart = DateTime.fromMillis(tt.startTime, { zone: timezone });
            const ttEnd = DateTime.fromMillis(tt.endTime, { zone: timezone });

            // The calendar can't handle taken times that cover multiple days.
            // This can happen if e.g. a booking starts at 12:30am and has 1hr
            // prep time before the booking.
            //
            // Split the taken times by day boundary
            const ttSeparatedByDay: Interval[] = [];
            let dayStart = ttStart;
            while (dayStart < ttEnd) {
              const dayEnd = DateTime.min(dayStart.endOf("day"), ttEnd);
              ttSeparatedByDay.push(Interval.fromDateTimes(dayStart, dayEnd));
              dayStart = dayStart.endOf("day").plus({ milliseconds: 1 });
            }

            return ttSeparatedByDay;
          })
          .filter((tt): tt is Interval<true> => tt.isValid)
          .map((tt) => ({ start: tt.start.toMillis(), end: tt.end.toMillis() }));
      } catch (e: unknown) {
        console.log(e instanceof Error ? e.message : "Unknown error on refetching events.");

        return [];
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedDate, modal.displayMode, timesQuery, resource.uuid, selectedTime],
  );

  return (
    <div className={`calendar-outer-cont ${className}`}>
      <div className="pb-3">
        <Text variant="label" color={theme.colors.grayscale[60]}>
          {t("bookings.operations.availability")}
        </Text>
      </div>
      <div className="calendar-cont">
        <CalendarView
          getBlockedTimesCb={getEventsCb}
          timezone={timezone}
          selectedDate={target}
          selectedTime={selectedTimeDebounced}
        />
      </div>
      <style jsx>{`
        .calendar-cont {
          background: #fff;
          padding: ${theme.spacers.s5};
          border-radius: ${theme.borderRadius};
        }
      `}</style>
    </div>
  );
};
