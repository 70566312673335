import React, { useContext, useState } from "react";
import { useRouter } from "next/router";
import { Tag, Dropdown, useTheme } from "@equiem/react-admin-ui";
import { RiDeleteBinLine, RiEditLine, RiEyeLine, RiMoreLine, RiCheckboxCircleLine } from "@equiem/react-admin-ui/icons";

import type { SiteBookingsQuery } from "../../../generated/gateway-client";
import { BookingStatus } from "../../../generated/gateway-client";
import { OperationsCancelBookingModal } from "./OperationsCancelBookingModal";
import { OperationsApproveBookingModal } from "./OperationsApproveBookingModal";
import { useTranslation } from "@equiem/localisation-eq1";
import { canEditBooking } from "../libs/canEditBooking";
import { canCancelBooking } from "../libs/canCancelBooking";
import { BookingModal } from "../contexts/BookingModalContext";

export interface Props {
  booking: NonNullable<SiteBookingsQuery["siteBookingsList"]["edges"][number]["node"]>;
}
export const OperationActions: React.FC<Props> = ({ booking }) => {
  const theme = useTheme(true);
  const router = useRouter();
  const modal = useContext(BookingModal);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const { t } = useTranslation();

  const routeToPath = async (pathname: string) => {
    await router.push(pathname);
  };

  const canEdit = canEditBooking(booking);
  const canCancel = canCancelBooking(booking);
  const needsApproval = canEdit && booking.status === BookingStatus.PendingApproval;

  return (
    <div className="d-flex justify-content-end align-items-center action-menu-cont">
      {booking.status === BookingStatus.Cancelled && <Tag className="mr-2">{t("bookings.operations.cancelled")}</Tag>}
      {booking.status === BookingStatus.Declined && (
        <Tag className="mr-2">{t("bookings.operations.status.declined")}</Tag>
      )}
      {booking.status === BookingStatus.PendingApproval && booking.isEditable && (
        <Tag className="mr-2" variant="dark">
          {t("bookings.operations.status.pendingApproval")}
        </Tag>
      )}
      <Dropdown.Icon placement="left-end" size="sm" icon={RiMoreLine} className="action-menu">
        <Dropdown.Item
          onClick={() => {
            routeToPath(`/bookings/operations/${booking.reference}`).catch(console.log);
          }}
        >
          <RiEyeLine size="16" color={theme.colors.primary} className="mr-3" /> {t("bookings.operations.view")}
        </Dropdown.Item>
        {needsApproval && (
          <Dropdown.Item onClick={() => setShowApproveModal(true)}>
            <RiCheckboxCircleLine size="16" color={theme.colors.primary} className="mr-3" />
            {t("bookings.operations.approveOrDecline")}
          </Dropdown.Item>
        )}
        {canEdit && (
          <Dropdown.Item
            onClick={() => {
              modal.open(booking.reference);
            }}
          >
            <RiEditLine size="16" color={theme.colors.primary} className="mr-3" />
            {t("common.edit")}
          </Dropdown.Item>
        )}
        {canCancel && (
          <Dropdown.Item onClick={() => setShowCancelModal(true)}>
            <RiDeleteBinLine color={theme.colors.danger} size="16" className="mr-3" />
            {t("bookings.operations.cancelBooking")}
          </Dropdown.Item>
        )}
      </Dropdown.Icon>
      <OperationsCancelBookingModal
        booking={booking}
        showModal={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
      <OperationsApproveBookingModal
        booking={booking}
        showModal={showApproveModal}
        onHide={() => setShowApproveModal(false)}
      />
    </div>
  );
};
