import { Site } from "@equiem/lib";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import type { ResourceCatalogueQuery } from "../../../generated/gateway-client";
import { getAvailabilityDateRange } from "../../../lib/getAvailabilityDateRange";

type BookableResourcesListItem = ResourceCatalogueQuery["myResourceCatalogue"][number];

const INTERVAL_MS = 1000;

const isAvailabilityPassed = (resource: BookableResourcesListItem, timezone: string) => {
  const endDate = getAvailabilityDateRange(
    resource.availabilityDateRange,
    resource.bookingWindowMinInMinutes,
    resource.bookingWindowMaxInMinutes,
    timezone,
  ).endDate;
  return endDate === null ? false : DateTime.local() > endDate;
};

export const useNotBokable = (resource: BookableResourcesListItem) => {
  const { uuid: siteUuid, timezone: siteTimezone } = useContext(Site);

  const timezone = resource.building?.timezone ?? siteTimezone;
  const [availabilityPassed, setAvailabilityPassed] = useState(() => {
    return isAvailabilityPassed(resource, timezone);
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      setAvailabilityPassed(isAvailabilityPassed(resource, timezone));
    }, INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [resource, timezone]);

  const canProxyBook = resource.viewerPermissions?.canManageBookings === true && resource.destination.uuid === siteUuid;
  const notBookable = availabilityPassed && !canProxyBook;

  return notBookable;
};
