import type { UIEvent } from "react";
import React, { useState } from "react";
import Link from "next/link";
import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import { Dropdown, Skeleton, useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import { RiMoreLine, RiEyeOffLine, RiUploadLine, RiFileCopyLine, RiDeleteBinLine } from "@equiem/react-admin-ui/icons";

import type { ResourceCatalogueQuery } from "../../../generated/gateway-client";
import { BookableResourceStatus } from "../../../generated/gateway-client";
import { resourceTypeToString } from "../../../lib/resourceTypeToString";
import { useUpdateResourceStatus } from "../../../hooks/useUpdateResourceStatus";
import { useCloneResource } from "../../../hooks/useCloneResource";
import { useCancelResourceDeletion } from "../../../hooks/useCancelResourceDeletion";
import { ValuePills } from "../../../components/ValuePills";
import { ResouceCardImage } from "./ResourceCardImage";
import { ResourceStatus } from "./ResourceStatus";
import { DeleteResourceModal } from "./delete/DeleteResourceModal";
import { useTranslation } from "@equiem/localisation-eq1";
import { ResourceCardCapacity } from "../../../components/ResourceCardCapacity";

const imgWidth = 307;
const imgHeight = 170;
const imgMobileCoefficient = 2.5;

type Resource = ResourceCatalogueQuery["myResourceCatalogue"][number];

const withDefaultPrevented =
  (cb: () => unknown = () => null) =>
  (e: UIEvent) => {
    e.preventDefault();
    cb();
  };

export const ResourceCardLoading: React.FC<{ className?: string }> = ({ className = "" }) => {
  const theme = useTheme(true);

  return (
    <div className={className}>
      <div className="mb-2">
        <Skeleton.Line width={`${imgWidth}px`} height={`${imgHeight}px`} borderRadius={theme.borderRadius} />
      </div>
      <div>
        <Skeleton.Line width="200px" height="18px" />
      </div>
      <Skeleton.Line width="100px" height="10px" />
    </div>
  );
};

export const ResourceCard: React.FC<Resource & { triggerRefresh: () => unknown }> = ({
  images,
  typeInfo,
  name,
  status,
  uuid,
  displayCapacity,
  deletedAfter,
  building,
  triggerRefresh,
}) => {
  const { t } = useTranslation();
  const site = useSiteContext();
  const { width } = useWindowDimensions();
  const theme = useTheme(true);
  const url = stringNotEmpty(images[0])
    ? `${images[0]}?auto=compress&fit=crop&crop=entropy&h=${imgHeight * imgMobileCoefficient}&w=${
        imgWidth * imgMobileCoefficient
      }`
    : "";

  const updateResourceStatus = useUpdateResourceStatus({ uuid, name, onSuccess: triggerRefresh });
  const cloneResource = useCloneResource({ uuid, name });
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const cancelDeletion = useCancelResourceDeletion({ uuid, name, onSuccess: triggerRefresh });

  return (
    <>
      <Link href={`/bookings/resources/${uuid}`} passHref legacyBehavior>
        <a className="resource-card">
          <ResouceCardImage
            url={url}
            rounded
            width={width > theme.breakpoints.md ? `${imgWidth}px` : "auto"}
            height={width > theme.breakpoints.md ? `${imgHeight}px` : "auto"}
            topFloatingEl={
              <div className="d-flex justify-content-end">
                <ResourceCardCapacity capacity={displayCapacity} />
              </div>
            }
          />
          <div className="body">
            <ResourceStatus
              className="my-4"
              status={status}
              deletedAfter={deletedAfter}
              timezone={building?.timezone ?? site.timezone}
            />
            <span className="title pb-2">{name}</span>
            <ValuePills>
              <span>{resourceTypeToString(typeInfo.name, t)}</span>
            </ValuePills>
            <Dropdown.Icon
              size="sm"
              placement="right-end"
              icon={RiMoreLine}
              className="action-menu"
              onClick={withDefaultPrevented()}
            >
              {[BookableResourceStatus.Draft, BookableResourceStatus.Published].includes(status) && (
                <>
                  {status === BookableResourceStatus.Draft && (
                    <Dropdown.Item
                      onClick={withDefaultPrevented(async () => updateResourceStatus(BookableResourceStatus.Published))}
                    >
                      <RiUploadLine size="16" color={theme.colors.primary} className="mr-3" />{" "}
                      {t("bookings.resources.publish")}
                    </Dropdown.Item>
                  )}
                  {status === BookableResourceStatus.Published && (
                    <Dropdown.Item
                      onClick={withDefaultPrevented(async () => updateResourceStatus(BookableResourceStatus.Draft))}
                    >
                      <RiEyeOffLine size="16" color={theme.colors.primary} className="mr-3" />{" "}
                      {t("bookings.resources.draft")}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Divider />
                </>
              )}

              <Dropdown.Item onClick={withDefaultPrevented(cloneResource)}>
                <RiFileCopyLine size="16" color={theme.colors.primary} className="mr-3" />{" "}
                {t("bookings.resources.duplicate")}
              </Dropdown.Item>
              {![BookableResourceStatus.PendingDeletion, BookableResourceStatus.Deleted].includes(status) && (
                <Dropdown.Item onClick={withDefaultPrevented(() => setDeleteModalVisible(true))}>
                  <RiDeleteBinLine size="16" color={theme.colors.danger} className="mr-3" />{" "}
                  {t("bookings.resources.scheduleDeletion")}
                </Dropdown.Item>
              )}
              {status === BookableResourceStatus.PendingDeletion && (
                <Dropdown.Item onClick={withDefaultPrevented(cancelDeletion)}>
                  <RiDeleteBinLine size="16" color={theme.colors.primary} className="mr-3" />{" "}
                  {t("bookings.resources.cancelDeletion")}
                </Dropdown.Item>
              )}
            </Dropdown.Icon>
          </div>
        </a>
      </Link>
      <DeleteResourceModal
        resourceUuid={uuid}
        visible={deleteModalVisible}
        onClose={() => {
          setDeleteModalVisible(false);
          triggerRefresh();
        }}
      />
      <style jsx>{`
        .resource-card {
          display: flex;
          flex-direction: column;
          position: relative;
          width: ${imgWidth}px;
          height: 100%;
          overflow: hidden;
          text-decoration: none;
        }
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .resource-card {
            width: 100%;
          }
        }
        .body {
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .title {
          color: ${theme.colors.dark};
          font-size: 1.2rem;
          font-weight: bold;
        }
        :hover .title {
          text-decoration: underline;
        }
        .resource-card :global(.value-pills) {
          font-size: 12px;
          color: ${theme.colors.muted0};
        }
        .resource-card :global(.action-menu) {
          position: absolute;
          top: 0.5rem;
          right: 0;
          z-index: 2;
        }
      `}</style>
    </>
  );
};
