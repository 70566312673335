import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Tag, Text, useTheme } from "@equiem/react-admin-ui";
import { RiArrowDownSLine, RiArrowUpSLine } from "@equiem/react-admin-ui/icons";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import type { FormValues } from "../../../../../lib/formValidation";

type AccordionItem = {
  title: string;
  key: string;
  content: React.ReactNode;
};

interface Props {
  items: AccordionItem[];
}

export const ResourceCreateAndEditFormAccordion: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const { colors, spacers } = useTheme(true);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const expandedItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expandedItemRef.current != null) {
      expandedItemRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [expandedIndex]);

  const toggleAccordion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const sectionHasErrors = (sectionKey: string): boolean => {
    switch (sectionKey) {
      case "details":
        return [fm.errors.title, fm.errors.typeV2, fm.errors.displayCapacity, fm.errors.building].some((error) =>
          stringNotEmpty(error),
        );
      case "rates":
        return [fm.errors.businessHoursEnd, fm.errors.paymentMethods].some((error) => stringNotEmpty(error));
      case "availability":
        return [
          fm.errors.start,
          fm.errors.bookingWindowMaxInMinutes,
          typeof fm.errors.availability === "string" ? fm.errors.availability : undefined,
        ].some((error) => stringNotEmpty(error));
      case "permissions":
        return [fm.errors.autoApproveBookings].some((error) => stringNotEmpty(error));
      case "additional-settings":
        return [fm.errors.ownerCompanyUuid].some((error) => stringNotEmpty(error));
      default:
        return false;
    }
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div className="accordion-item" key={item.key} ref={index === expandedIndex ? expandedItemRef : null}>
          <div
            className={`accordion-title-container ${index === expandedIndex ? "expanded" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="accordion-title">
              <Text variant="text" component="span" size="small" weight="bold">
                {item.title}
              </Text>
              {sectionHasErrors(item.key) && (
                <Tag variant="negative" size="large">
                  {t("bookings.resources.requiredFieldMissing")}
                </Tag>
              )}
            </div>
            <div className="accordion-chevron">
              {index === expandedIndex ? <RiArrowUpSLine size={24} /> : <RiArrowDownSLine size={24} />}
            </div>
          </div>
          {index === expandedIndex && <div className="accordion-content">{item.content}</div>}
        </div>
      ))}

      <style jsx>{`
        .accordion {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s4};
        }
        .accordion-item {
          display: flex;
          flex-direction: column;
          padding: ${spacers.s4} ${spacers.s5};
          gap: ${spacers.s6};
          border: 1px solid ${colors.border};
          border-radius: 8px;
        }
        .accordion-title-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
        .accordion-title {
          display: flex;
          gap: ${spacers.s3};
          align-items: center;
        }
        .accordion-chevron {
          padding: ${spacers.s2};
        }
        .accordion-content {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s7};
        }
        .accordion-content :global(.form-group) {
          margin-bottom: ${spacers.s0};
        }
        .accordion-content :global(hr) {
          margin: ${spacers.s0};
        }
      `}</style>
    </div>
  );
};
