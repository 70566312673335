import { stringNotEmpty } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme, Skeleton, useDebounced } from "@equiem/react-admin-ui";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { BookingFormFlexibleTime } from "./BookingFormFlexibleTime";
import { BookingFormSlotsTime } from "./BookingFormSlotsTime";
import { BookableResourceAvailabilityType, useListingTimesQuery } from "../../../generated/gateway-client";
import type { ListingTimesQuery } from "../../../generated/gateway-client";
import { getAvailabilityDateRange } from "../../../lib/getAvailabilityDateRange";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { dateFormat, timeFormat } from "../libs/formatSelectedTime";
import { BookingModal } from "../contexts/BookingModalContext";
import type { BookingFormValue } from "../models/BookingFormValue";
import { useFormikContext } from "formik";

type QueryListingTime = ListingTimesQuery["bookableResource"]["listingTimes"][number];
type ListingTime = Omit<QueryListingTime, "times"> & {
  times: Array<
    QueryListingTime["times"][number] & {
      startHourMin: string;
      endHourMin: string;
    }
  >;
};

const debounceTime = 500;

const toListingTime = (lt: QueryListingTime, timezone: string): ListingTime => {
  return {
    ...lt,
    times: lt.times.map((time) => ({
      ...time,
      startHourMin: DateTime.fromMillis(time.start, { zone: timezone }).toFormat("HH:mm"),
      endHourMin: DateTime.fromMillis(time.end, { zone: timezone }).toFormat("HH:mm"),
    })),
  };
};

const BookingFormAvailableTime: React.FC<{ selectedDate: string }> = ({ selectedDate }) => {
  const { t } = useTranslation();
  const { booking, resource, timezone } = useContext(BookingModalInfo);
  const { borderRadius, spacers } = useTheme();
  const modal = useContext(BookingModal);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dt = DateTime.fromFormat(selectedDate, dateFormat, { zone: timezone });
  const { date, skip } = useDebounced({ date: dt.toMillis(), skip: !dt.isValid }, debounceTime);
  const query = useListingTimesQuery({
    variables: {
      uuid: resource.uuid,
      date,
      excludeBooking: booking?.uuid,
    },
    fetchPolicy: "no-cache",
    skip,
  });
  const listingTimes = (query.data?.bookableResource.listingTimes ?? []).map<ListingTime>((lt) =>
    toListingTime(lt, timezone),
  );
  const flexibleMode = listingTimes.some((tl) => tl.type === BookableResourceAvailabilityType.Flexible);

  useEffect(() => {
    modal.setCanSubmitForm(!query.loading);
  }, [modal, query.loading]);

  useEffect(() => {
    if (query.error != null || listingTimes.length === 0) {
      modal.setCanSubmitForm(false);
      setErrorMessage(
        query.error != null ? t("bookings.operations.failedToLoad") : t("bookings.operations.noAvailability"),
      );
    } else {
      setErrorMessage(null);
    }
  }, [query.error, listingTimes.length, t, modal]);

  if (query.loading) {
    return (
      <div className="loading-skeleton">
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <Skeleton.Line height="30px" width="100px" borderRadius={borderRadius} />
        <style jsx>{`
          .loading-skeleton {
            display: flex;
            gap: ${spacers.s3};
            flex-wrap: wrap;
            padding: 0 0 ${spacers.s6};
          }
        `}</style>
      </div>
    );
  }

  if (errorMessage !== null) {
    return (
      <Form.Group label={t("common.time")} required>
        {errorMessage}
      </Form.Group>
    );
  }

  return (
    <>
      {flexibleMode ? (
        <BookingFormFlexibleTime listingTimes={listingTimes} />
      ) : (
        <BookingFormSlotsTime listingTimes={listingTimes} date={date} />
      )}
    </>
  );
};

export const BookingFormTime: React.FC = () => {
  const { t } = useTranslation();
  const { resource, timezone } = useContext(BookingModalInfo);
  const fm = useFormikContext<BookingFormValue>();
  const modal = useContext(BookingModal);
  const hasSuperPower = fm.values.hasSuperPower === true;

  const dateInAvailabilityrange = useMemo(() => {
    if (fm.values.date == null) {
      return false;
    }
    if (hasSuperPower) {
      return true;
    }

    const date = DateTime.fromFormat(fm.values.date, dateFormat, { zone: timezone });
    const range = getAvailabilityDateRange(
      resource.availabilityDateRange,
      resource.bookingWindowMinInMinutes,
      resource.bookingWindowMaxInMinutes,
      timezone,
    );
    return date >= range.startDate.startOf("day") && (range.endDate == null || date <= range.endDate.endOf("day"));
  }, [
    fm.values.date,
    hasSuperPower,
    timezone,
    resource.availabilityDateRange,
    resource.bookingWindowMinInMinutes,
    resource.bookingWindowMaxInMinutes,
  ]);

  useEffect(() => {
    if (!dateInAvailabilityrange) {
      modal.setCanSubmitForm(false);
    }
  }, [dateInAvailabilityrange, modal]);

  useEffect(() => {
    if (stringNotEmpty(fm.values.date) && stringNotEmpty(fm.values.start) && stringNotEmpty(fm.values.end)) {
      const dateDT = DateTime.fromFormat(fm.values.date, dateFormat, { zone: timezone });
      const startDT = DateTime.fromFormat(fm.values.start, timeFormat);
      const endDT = DateTime.fromFormat(fm.values.end, timeFormat);
      modal.setStart(dateDT.plus({ hour: startDT.hour, minute: startDT.minute }).toMillis());
      modal.setEnd(dateDT.plus({ hour: endDT.hour, minute: endDT.minute }).toMillis());
    } else {
      modal.setStart(undefined);
      modal.setEnd(undefined);
    }
  }, [fm.values.start, fm.values.end, fm.values.date, modal, timezone]);

  return (
    <>
      {fm.values.date != null && dateInAvailabilityrange ? (
        <BookingFormAvailableTime selectedDate={fm.values.date} />
      ) : (
        <Form.Group label={t("common.time")} required>
          {t("bookings.operations.noAvailability")}
        </Form.Group>
      )}
    </>
  );
};
