import { useSiteContext } from "@equiem/lib";
import { useApolloErrorTranslation } from "@equiem/localisation-eq1";
import { Skeleton, useTheme } from "@equiem/react-admin-ui";
import { RiBuilding4Fill } from "@equiem/react-admin-ui/icons";
import { useFormikContext } from "formik";
import React from "react";
import { ValuePills } from "../../../../../components/ValuePills";
import type { FormValues } from "../../../../../lib/formValidation";
import { useAuthorizedDestinationDetails } from "../../../hooks/useAuthorizedDestinationDetails";

export const ResourceCreateAndEditFormPreviewLocation: React.FC = () => {
  const { tError } = useApolloErrorTranslation();
  const { colors } = useTheme(true);
  const site = useSiteContext();

  const { values } = useFormikContext<FormValues>();

  const { buildings, loading, error } = useAuthorizedDestinationDetails(site.uuid, {
    fetchPolicy: "cache-and-network",
    includeBuildingLevels: values.level == null ? [] : [values.level],
  });
  const selectedBuilding = buildings.find((b) => b.uuid === values.building);
  const selectedLevel = (selectedBuilding?.buildingLevels ?? []).find((l) => l.uuid === values.level);

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  if (loading || selectedBuilding == null) {
    return <Skeleton.Line width="100%" height="42px" />;
  }

  return (
    <div className="location-cont">
      <RiBuilding4Fill size={16} className="mr-5" />
      <ValuePills>
        <span className="site-name-pill">{site.name}</span>
        <span className="building-name-pill">{selectedBuilding.name}</span>
        {selectedLevel != null && <span className="level-name-pill">{selectedLevel.name}</span>}
        <span className="building-address-pill">{selectedBuilding.address}</span>
      </ValuePills>

      <style jsx>{`
        .location-cont {
          color: ${colors.grayscale[60]};
          font-weight: 300;
          line-height: 1.5em;
        }
      `}</style>
    </div>
  );
};
