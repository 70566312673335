import { stringIsEmpty } from "@equiem/lib";
import { DateTime } from "luxon";
import type { PropsWithChildren } from "react";
import React, { createContext, useState, useMemo, useCallback } from "react";
import { dateFormat, formatSelectedTime } from "../libs/formatSelectedTime";

type SelectedDate = { date: string; timezone: string };
type SelectedTimeInput = { start?: string | null; end?: string | null };

interface BookingCalendarContextType {
  selectedDate: SelectedDate;
  selectedTime: { start?: number; end?: number } | null;
  setSelectedDate: (timezone: string, date: string) => void;
  setSelectedTime: (input: SelectedTimeInput) => void;
}

export const BookingCalendarContext = createContext<BookingCalendarContextType>({
  selectedDate: {
    date: DateTime.local().toFormat(dateFormat),
    timezone: "Australia/Melbourne",
  },
  selectedTime: null,
  setSelectedDate: () => undefined,
  setSelectedTime: () => undefined,
});

export const BookingCalendarContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState<SelectedDate>({
    date: DateTime.local().toFormat(dateFormat),
    timezone: "Australia/Melbourne",
  });
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");

  const handleSelectedDateChange = useCallback((timezone: string, newSelectedDate: string) => {
    if (stringIsEmpty(newSelectedDate) || stringIsEmpty(timezone)) {
      return;
    }

    const parsed = DateTime.fromFormat(newSelectedDate, dateFormat);
    if (!parsed.isValid) {
      return;
    }

    setSelectedDate({ date: parsed.toFormat(dateFormat), timezone });
  }, []);

  const setSelectedTime = useCallback((input: SelectedTimeInput) => {
    if (input.start !== undefined) {
      setSelectedStartTime(input.start ?? "");
    }
    if (input.end !== undefined) {
      setSelectedEndTime(input.end ?? "");
    }
  }, []);

  const selectedTime = useMemo(() => {
    return formatSelectedTime(selectedDate.timezone, selectedDate.date, selectedStartTime, selectedEndTime);
  }, [selectedStartTime, selectedEndTime, selectedDate]);

  return (
    <BookingCalendarContext.Provider
      value={{
        selectedDate,
        setSelectedDate: handleSelectedDateChange,
        selectedTime,
        setSelectedTime,
      }}
    >
      {children}
    </BookingCalendarContext.Provider>
  );
};
