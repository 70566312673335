import { useContext, useState } from "react";
import { Site, notNullOrUndefined } from "@equiem/lib";
import type { FilterItem, FilterValue } from "@equiem/react-admin-ui";
import { FilterDateModifier } from "@equiem/react-admin-ui";
import { RiStore2Line, RiDeleteBinLine, RiMoneyDollarBoxLine, RiBankCardLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";

import {
  BookableResourcePaymentMethod,
  useSiteCompaniesQuery,
  useBookingsReconciliationReportQuery,
} from "../../../generated/gateway-client";
import { useCurrencyCode } from "../../../hooks/useCurrency";
import type { ReportParams, Totals, Booking } from "../utils/ReportParams";
import { formatPaymentMethodLocalised } from "../utils/formatPaymentMethodLocalised";

type FilterKey = "date" | "company" | "cancelled" | "costOnly" | "paymentMethod";

const getSelectValue = <T extends string = string>(v: FilterValue | undefined): T | undefined =>
  v?.type === "select" ? (v.value as T) : undefined;

const toBoolean = (str: string | undefined) => ({ true: true, false: false }[str ?? ""] ?? undefined);

const getFilters = (values: Partial<Record<FilterKey, FilterValue>>, timezone: string) => {
  let startDate: number;
  let endDate: number;
  if (values.date?.type === "date" && values.date.value != null) {
    const [fromIso, untilIso] = Array.isArray(values.date.value)
      ? values.date.value
      : ([values.date.value, values.date.value] as const);

    startDate = DateTime.fromISO(fromIso, { zone: timezone }).startOf("day").toMillis();
    endDate = DateTime.fromISO(untilIso, { zone: timezone }).endOf("day").toMillis();
  } else {
    startDate = DateTime.now().setZone(timezone).minus({ months: 1 }).startOf("day").toMillis();
    endDate = DateTime.now().setZone(timezone).endOf("day").toMillis();
  }

  return {
    startDate,
    endDate,
    companyUuid: getSelectValue(values.company),
    cancelled: toBoolean(getSelectValue(values.cancelled)),
    costOnly: toBoolean(getSelectValue(values.costOnly)),
    paymentMethod: getSelectValue<BookableResourcePaymentMethod>(values.paymentMethod),
  };
};

export const useReportData = () => {
  const { t } = useTranslation();
  const { uuid: destinationUuid, timezone } = useContext(Site);
  const fallbackCurrencyCode = useCurrencyCode();

  const {
    data: companiesData,
    error: companiesError,
    loading: companiesLoading,
  } = useSiteCompaniesQuery({ variables: { destinationUuid } });
  const companies =
    companiesData?.destination.companiesV2?.edges
      .map((edge) => edge.node)
      .filter(notNullOrUndefined)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((c) => ({ value: c.uuid, label: c.name })) ?? [];

  const filterItems: Record<string, FilterItem> = {
    date: {
      title: t("common.date"),
      type: "date",
      modifiers: [FilterDateModifier.is, FilterDateModifier.between],
    },
    company: {
      title: t("common.company"),
      type: "select",
      icon: RiStore2Line,
      options: companies,
    },
    cancelled: {
      title: t("bookings.reports.cancelled"),
      type: "select",
      icon: RiDeleteBinLine,
      options: [
        { value: "false", label: t("common.exclude") },
        { value: "true", label: t("common.only") },
      ],
    },
    costOnly: {
      title: t("bookings.reports.costOnly"),
      type: "select",
      icon: RiMoneyDollarBoxLine,
      options: [
        { value: "true", label: t("common.yes") },
        { value: "false", label: t("common.no") },
      ],
    },
    paymentMethod: {
      title: t("bookings.reports.paymentMethod"),
      type: "select",
      icon: RiBankCardLine,
      options: Object.values(BookableResourcePaymentMethod).map((value) => ({
        value,
        label: formatPaymentMethodLocalised(value, t),
      })),
    },
  };
  const [filterValues, setFilterValues] = useState<Partial<Record<FilterKey, FilterValue>>>({});
  const filters = getFilters(filterValues, timezone);

  const { data, error, loading } = useBookingsReconciliationReportQuery({
    variables: { filters: { ...filters, siteUuid: [destinationUuid] } },
  });

  const bookings: Booking[] = data?.bookingsReportReconciliation.bookings ?? [];
  const totals: Totals = data?.bookingsReportReconciliation.totals ?? {
    adjustmentsTotalPrice: 0,
    allPartialRefundsTotalPrice: 0,
    durationHours: 0,
    resourcePrice: 0,
    discountTotalAmount: 0,
    addOnsPrice: 0,
    cancellationPrice: 0,
    totalTax: 0,
    totalPrice: 0,
  };
  const currencyCode = data?.bookingsReportReconciliation.currencyCode ?? fallbackCurrencyCode;
  const yardiEnabled = data?.bookingsReportReconciliation.yardiEnabled ?? false;

  const reportParams: ReportParams = {
    companyName: companies.find(({ value }) => value === filters.companyUuid)?.label,
    costOnly: filters.costOnly,
    cancelled: filters.cancelled,
    paymentMethod: filters.paymentMethod,
    startDate: filters.startDate,
    endDate: filters.endDate,
    timezone,
    bookings,
    totals,
    currencyCode,
  };

  return {
    filterItems,
    setFilterValues,

    error: error ?? companiesError,
    loading: loading || companiesLoading,

    bookings,
    totals,
    currencyCode,

    reportParams,
    yardiEnabled,
  };
};
