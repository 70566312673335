import React, { useContext, useState } from "react";
import Head from "next/head";
import { MainContainer, SubTopMenu, useDisabledSiteSwitcher } from "@equiem/lib";
import { Tag, useTheme, Skeleton, Button } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import { BookingStatus, CancellingUserRole, useSiteBookingByReferenceQuery } from "../../generated/gateway-client";
import { OperationsCancelBookingModal } from "./components/OperationsCancelBookingModal";
import { OperationsApproveBookingModal } from "./components/OperationsApproveBookingModal";
import { BookingDetails } from "./components/BookingDetails";
import { BookingDetailsCharges } from "./components/BookingDetailsCharges";
import { canEditBooking } from "./libs/canEditBooking";
import { canCancelBooking } from "./libs/canCancelBooking";
import { withContexts } from "../../contexts/withContexts";
import { BookingModal } from "./contexts/BookingModalContext";
import { BookingViewModal } from "./components/BookingViewModal";

const BookingDetailsLoading: React.FC = () => {
  const theme = useTheme(true);

  if (process.env.bookingMultiSiteEnabled === "true") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDisabledSiteSwitcher();
  }

  return (
    <>
      <div className="cont">
        <SubTopMenu>
          <Skeleton.Line height="25px" width="300px" />
        </SubTopMenu>
        <MainContainer className="pt-6">
          <div className="mb-6">
            <Skeleton.Line height="20px" width="120px" />
          </div>
          <div>
            <Skeleton.Line height="20px" width="80px" />
          </div>
          <Skeleton.Line height="20px" width="280px" />
        </MainContainer>
      </div>
      <style jsx>{`
        .cont {
          background: ${theme.colors.white};
          min-height: calc(100vh - 65px);
        }
      `}</style>
    </>
  );
};

export const BookingDetailsLocal: React.FC<{ reference: string }> = ({ reference }) => {
  const { breakpoints, colors } = useTheme(true);
  const { data, loading, refetch } = useSiteBookingByReferenceQuery({
    fetchPolicy: "network-only",
    variables: {
      reference,
    },
  });
  const modal = useContext(BookingModal);
  const { t } = useTranslation();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const booking = data?.siteBookingByReference;

  if (loading) {
    return <BookingDetailsLoading />;
  }
  if (booking == null) {
    return null;
  }

  const canEdit = canEditBooking(booking);
  const canCancel = canCancelBooking(booking);

  let cancelledMessage: string;
  switch (booking.cancelledByRole) {
    case CancellingUserRole.Host:
      cancelledMessage = t("bookings.operations.cancelledByHost");
      break;
    case CancellingUserRole.System:
      cancelledMessage = t("bookings.operations.cancelledBySystem");
      break;
    case CancellingUserRole.User:
      cancelledMessage = t("bookings.operations.cancelledByUser");
      break;
    default:
      cancelledMessage = t("bookings.operations.cancelled");
      break;
  }

  return (
    <>
      <Head>
        <title>{t("bookings.operations.bookingsReferenceDetails", { bookingReference: booking.reference })}</title>
      </Head>
      <div style={{ background: colors.white }} className="menu">
        <SubTopMenu>
          <h1 className="font-weight-bold">
            {booking.resource.name}
            <Tag size="large" className="ml-3">
              {booking.reference}
            </Tag>
            {booking.status === BookingStatus.Cancelled && (
              <Tag size="large" variant="negative" className="ml-3">
                {cancelledMessage}
              </Tag>
            )}
            {booking.status === BookingStatus.Declined && (
              <Tag size="large" variant="negative" className="ml-3">
                {t("bookings.operations.status.declined")}
              </Tag>
            )}
            {booking.status === BookingStatus.PendingApproval && booking.isEditable && (
              <Tag size="large" variant="dark" className="ml-3">
                {t("bookings.operations.status.pendingApproval")}
              </Tag>
            )}
          </h1>
          {booking.isEditable && (
            <div className="d-flex align-items-center">
              {booking.status === BookingStatus.PendingApproval && (
                <Button
                  variant="outline"
                  className="mr-3 text-uppercase"
                  size="md"
                  onClick={() => setShowApproveModal(true)}
                >
                  {t("bookings.operations.approveOrDecline")}
                </Button>
              )}
              {canEdit && (
                <Button
                  variant="secondary"
                  className="mr-3 text-uppercase"
                  size="md"
                  onClick={() => {
                    modal.open(booking.reference);
                  }}
                >
                  {t("bookings.operations.editBooking")}
                </Button>
              )}
              {canCancel && (
                <Button variant="danger" className="text-uppercase" size="md" onClick={() => setShowCancelModal(true)}>
                  {t("bookings.operations.cancelBooking")}
                </Button>
              )}
            </div>
          )}
        </SubTopMenu>
      </div>
      <div className="grid">
        <div className="left p-6">
          <BookingDetails booking={booking} />
        </div>
        <div className="right p-6">
          <BookingDetailsCharges
            booking={booking}
            bookingRefetch={() => {
              refetch().catch(console.log);
            }}
          />
        </div>
      </div>
      <OperationsCancelBookingModal
        booking={booking}
        showModal={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
      <OperationsApproveBookingModal
        booking={booking}
        showModal={showApproveModal}
        onHide={() => setShowApproveModal(false)}
      />
      <BookingViewModal />
      <style jsx>{`
        @media (min-width: ${breakpoints.md}px) {
          .grid {
            display: grid;
            grid-template-columns: 60% 40%;
            min-height: calc(100vh - 130px);
          }
        }
        .left {
          background: ${colors.white};
        }
        .right {
          background: ${colors.grayscale[3]};
        }
        .cancelled {
          color: ${colors.danger};
        }
      `}</style>
    </>
  );
};

export const BookingDetailsPage = withContexts(BookingDetailsLocal, "adminForm");
