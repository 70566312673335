import React from "react";
import { BaseTimeRange } from "./BaseTimeRange";
import type { ResourceCatalogueQuery } from "../../../../generated/gateway-client";

type BookableResourcesListItem = ResourceCatalogueQuery["myResourceCatalogue"][number];

export const AvailableTimeRange: React.FC<{
  start: number;
  end: number;
  timezone: string;
  resource: BookableResourcesListItem;
  notBookable: boolean;
  setReloadUuid: (_uuid: string) => void;
}> = ({ start, end, timezone, resource, notBookable, setReloadUuid }) => {
  return (
    <BaseTimeRange
      start={start}
      end={end}
      timezone={timezone}
      className="active-time-slot available-time-range"
      resource={resource}
      notBookable={notBookable}
      setReloadUuid={setReloadUuid}
    />
  );
};
