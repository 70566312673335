import type { FC } from "react";
import { useContext, Fragment } from "react";
import { useTheme, Dropdown } from "@equiem/react-admin-ui";
import type { BreadcrumbsObject } from "../../contexts/BreadcrumbsContext";
import { Breadcrumbs as BreadcrumbsContext } from "../../contexts/BreadcrumbsContext";
import { GradientContext } from "@equiem/lib/GradientContext";
import Link from "next/link";
import {
  RiArrowRightSLine,
  RiCalendarCheckLine,
  RiMoreLine,
  RiQuestionnaireLine,
  RiTeamLine,
} from "@equiem/react-admin-ui/icons";
import type { IconType } from "react-icons";
import type { TFunction } from "@equiem/localisation-eq1";
import { useTranslation } from "@equiem/localisation-eq1";

type IBreadcrumbIconMap = Partial<Record<string, IconType>>;
type IBreadcrumbTranslationKeyMap = Partial<Record<string, string>>;

const getBreadcrumbTranslationKeyMap = (t: TFunction): IBreadcrumbTranslationKeyMap => ({
  "home": t("breadcrumbs.home"),
  "bookings": t("breadcrumbs.bookings"),
  "my bookings": t("breadcrumbs.myBookings"),
  "resources": t("breadcrumbs.resources"),
  "new resource": t("bookings.resources.newResource"),
  "operations": t("breadcrumbs.operations"),
  "create": t("breadcrumbs.create"),
  "edit": t("breadcrumbs.edit"),
  "reporting": t("breadcrumbs.reporting"),
  "payment": t("breadcrumbs.payment"),
  "visitor management": t("breadcrumbs.visitorManagement"),
  "all": t("breadcrumbs.all"),
  "settings": t("breadcrumbs.settings"),
  "receptions": t("breadcrumbs.receptions"),
  "create reception": t("breadcrumbs.createReception"),
  "edit reception": t("breadcrumbs.editReception"),
  "new appointment": t("breadcrumbs.newAppointment"),
  "edit appointment": t("breadcrumbs.editAppointment"),
  "categories": t("breadcrumbs.categories"),
  "request management": t("breadcrumbs.requestManagement"),
  "users": t("settings.users"),
  "companies": t("settings.companies"),
  "apartments": t("settings.apartments"),
  "insights": t("home.sideMenu.insights"),
  "credits": t("breadcrumbs.credits"),
  "credits activity": t("breadcrumbs.creditsActivity"),
  "discounts": t("breadcrumbs.discounts"),
});

const breadcrumbIconMap: IBreadcrumbIconMap = {
  "/bookings": RiCalendarCheckLine,
  "/request-management": RiQuestionnaireLine,
  "/visitor-management": RiTeamLine,
};

const BreadcrumbsDropdown: FC<{
  dropdownBreadcrumbs: BreadcrumbsObject[];
}> = ({ dropdownBreadcrumbs }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bc-dropdown">
        <Dropdown.Icon placement="bottom-end" size="sm" icon={RiMoreLine}>
          {dropdownBreadcrumbs.map(({ href, text }, i) => (
            <Link passHref key={i} href={href} legacyBehavior>
              <Dropdown.Item icon={breadcrumbIconMap[href] ?? RiArrowRightSLine}>
                <span className="bc-dropdown-link">{getBreadcrumbTranslationKeyMap(t)[text] ?? text}</span>
              </Dropdown.Item>
            </Link>
          ))}
        </Dropdown.Icon>
      </div>
      <style jsx>{`
        .bc-dropdown-link {
          text-transform: capitalize;
        }
      `}</style>
    </>
  );
};

export const Breadcrumbs: FC = () => {
  const showMoreItem = "MoreThan3:show_MORE_item";
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();
  const { breadcrumbs } = useContext(BreadcrumbsContext);
  const { showGradient } = useContext(GradientContext);

  // Show last 2.
  const BREADCRUMBS_AMOUNT = 2;
  // When breadcrumbs more than 3 we want to show show more.
  const MIN_BREADCRUMB_LENGTH = 3;
  const bcToShow =
    breadcrumbs.length > MIN_BREADCRUMB_LENGTH
      ? [
          breadcrumbs[0],
          { text: showMoreItem, href: "" },
          ...breadcrumbs.slice(breadcrumbs.length - BREADCRUMBS_AMOUNT),
        ]
      : breadcrumbs;

  if (bcToShow.length === 0) {
    return null;
  }

  return (
    <>
      {bcToShow.map((breadcrumb, index: number) => {
        if (breadcrumb.text === showMoreItem) {
          const dropdownBreadcrumbs = breadcrumbs.slice(1, -BREADCRUMBS_AMOUNT);
          return (
            <Fragment key={breadcrumb.text}>
              <div className="bc-arrow">
                <RiArrowRightSLine />
              </div>
              <BreadcrumbsDropdown dropdownBreadcrumbs={dropdownBreadcrumbs} />
            </Fragment>
          );
        }

        return (
          <div className="d-flex align-items-center" key={breadcrumb.text}>
            {index !== 0 && (
              <div className="bc-arrow">
                <RiArrowRightSLine />
              </div>
            )}
            <Link href={breadcrumb.href} passHref legacyBehavior>
              <a className="bc-link">{getBreadcrumbTranslationKeyMap(t)[breadcrumb.text] ?? breadcrumb.text}</a>
            </Link>
          </div>
        );
      })}
      <style jsx>{`
        .bc-arrow,
        .bc-link {
          color: ${showGradient ? colors.white : "#666"};
        }
        .bc-arrow {
          margin: 0 ${spacers.s2} 3px ${spacers.s2};
        }
        .bc-link {
          font-size: 13px;
          text-decoration: none;
          font-weight: 500;
          text-transform: uppercase;
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};
