import React, { type FC } from "react";
import { Alert, Button, ButtonLink, Tag, Text, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import { RiInformationLine } from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";

import { type BookingFragmentFragment, BookingStatus } from "../../../generated/gateway-client";
import { useLazyBookingAppointment, editAppointmentRoute } from "../hooks/useLazyBookingAppointment";

interface P {
  className?: string;
  booking: BookingFragmentFragment;
}

const TERMINAL_BOOKING_STATUS = [BookingStatus.Cancelled, BookingStatus.Declined, BookingStatus.PaymentFailed];

export const BookingAppointmentInformation: FC<P> = ({ className, booking }) => {
  const { goToAppointment, loading } = useLazyBookingAppointment();
  const { colors } = useTheme();
  const { t } = useTranslation();

  let infoMessage: string;
  let buttonText: string;
  if (booking.appointment != null) {
    infoMessage = t("bookings.visitors.updateVisitorsInfo");
    buttonText = t("bookings.visitors.updateAppointment");
  } else if (booking.status === BookingStatus.Approved) {
    infoMessage = t("bookings.visitors.addVisitorsOnUpdateInfo");
    buttonText = t("bookings.visitors.createAppointment");
  } else {
    infoMessage = t("bookings.visitors.addVisitorsPendingApprovalInfo");
    buttonText = t("bookings.visitors.createAppointment");
  }

  const buttonStyleProps = {
    className: "mt-4",
    variant: "outline",
  } as const;

  return (
    <div className={className}>
      {booking.appointment != null && (
        <Tag className="mb-4" size="large">
          {t("bookings.visitors.visitorsAdded", { count: booking.appointment.visitors.length })}
        </Tag>
      )}
      <Alert
        variant="gray"
        size="large"
        icon={<RiInformationLine size={18} color={colors.grayscale[50]} />}
        message={infoMessage}
      />
      {booking.appointment != null ? (
        <ButtonLink {...buttonStyleProps} href={editAppointmentRoute(booking.appointment.uuid)}>
          {buttonText}
        </ButtonLink>
      ) : (
        <Button
          {...buttonStyleProps}
          disabled={loading || booking.status !== BookingStatus.Approved}
          onClick={() => {
            goToAppointment(booking).catch(console.error);
          }}
        >
          {loading && <ProgressCircle size="xs" className="mr-2" />}
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export const BookingViewVisitors: FC<P> = ({ className, booking }) => {
  const { t } = useTranslation();

  if (booking.appointment == null && (booking.isPast || TERMINAL_BOOKING_STATUS.includes(booking.status))) {
    return null;
  }

  return (
    <div className={className}>
      <Text variant="heading" size="medium" className="pb-4">
        {t("visitors.common.visitors")}
      </Text>
      <BookingAppointmentInformation booking={booking} />
    </div>
  );
};
