import { DateTime } from "luxon";

import { notNullOrUndefined, stringNotEmpty } from "@equiem/lib";
import { FilterDateModifier, FilterIsModifier, FilterType } from "@equiem/react-admin-ui";

import type { ReqMgtRequestsDateFilter, StatusesQuery } from "../../../generated/requests-client";
import { ReqMgtRequestsFilterScope, ReqMgtStatusType } from "../../../generated/requests-client";
import type { ActiveTab } from "../contexts/ActiveTab";
import type { RequestFiltersResult } from "../contexts/RequestsFilterContext";
import type { RequestPageScope } from "../contexts/RequestsScopeContext";
const DATE_FORMAT = "yyyy-LL-dd";

export const translateSearchTerm = (filters: RequestFiltersResult) => {
  return filters.search != null && stringNotEmpty(filters.search.value as string | undefined)
    ? (filters.search.value as string)
    : undefined;
};
export const translateStatus = (
  filters: RequestFiltersResult,
  activeTab: ActiveTab,
  statuses?: StatusesQuery | undefined,
) => {
  const userSelectedStatusUuids =
    filters.status?.type === FilterType.options ? filters.status.value?.map((v) => v.value) ?? [] : undefined;

  if ((activeTab === "completed" || activeTab === "open") && statuses != null) {
    const allowedStatusUuids = statuses.reqMgt.statuses
      .filter((s) =>
        activeTab === "completed" ? s.type === ReqMgtStatusType.Completed : s.type !== ReqMgtStatusType.Completed,
      )
      .map((s) => s.uuid);

    if (userSelectedStatusUuids != null && userSelectedStatusUuids.length > 0) {
      const legalSelectedUserStatusUuids = allowedStatusUuids.filter(
        (x) => userSelectedStatusUuids.find((selectedUuid) => selectedUuid === x) != null,
      );

      // if previously selected status(-es) are not appropriate for the current tab -- default
      return legalSelectedUserStatusUuids.length > 0 ? legalSelectedUserStatusUuids : allowedStatusUuids;
    }

    return allowedStatusUuids;
  }

  return userSelectedStatusUuids;
};

export const translateCategory = (filters: RequestFiltersResult) => {
  if (filters.category?.type === FilterType.options) {
    const values = filters.category.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }
  return undefined;
};
export const translateBuilding = (
  activeTab: ActiveTab,
  scope: RequestPageScope,
  filters: RequestFiltersResult,
  allowedBuildings: string[],
) => {
  if (filters.building?.type === FilterType.options) {
    const values = filters.building.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }

  if (allowedBuildings.length > 0 && scope === "all") {
    return allowedBuildings;
  }

  return undefined;
};
export const translateReporterCompany = (
  activeTab: ActiveTab,
  scope: RequestPageScope,
  filters: RequestFiltersResult,
  companyUuid?: string,
) => {
  if (companyUuid != null && scope === "all") {
    return [companyUuid];
  }

  if (filters.company?.type === FilterType.options) {
    const values = filters.company.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }
  return undefined;
};
export const translateCreated = (filters: RequestFiltersResult): ReqMgtRequestsDateFilter | undefined => {
  if (filters.created?.type === FilterType.date) {
    if (filters.created.modifier === FilterDateModifier.is && filters.created.value != null) {
      return {
        start: DateTime.fromFormat(filters.created.value as string, DATE_FORMAT)
          .startOf("day")
          .toMillis(),
        end: DateTime.fromFormat(filters.created.value as string, DATE_FORMAT)
          .endOf("day")
          .toMillis(),
      };
    }
    if (filters.created.modifier === FilterDateModifier.between && filters.created.value?.[0] != null) {
      return {
        start: DateTime.fromFormat(filters.created.value[0] as string, DATE_FORMAT)
          .startOf("day")
          .toMillis(),
        end: DateTime.fromFormat(filters.created.value[1] as string, DATE_FORMAT)
          .endOf("day")
          .toMillis(),
      };
    }
  }
  return undefined;
};
export const translateWatching = (filters: RequestFiltersResult, currentUserUuid?: string) => {
  if (currentUserUuid == null) {
    return undefined;
  }

  if (
    filters.watchedByUuid?.type === FilterType.is &&
    filters.watchedByUuid.modifier === FilterIsModifier.is &&
    filters.watchedByUuid.value === (FilterIsModifier.is as string)
  ) {
    return currentUserUuid;
  }

  return undefined;
};
export const translateReporter = (scope: RequestPageScope, filters: RequestFiltersResult, currentUserUuid?: string) => {
  const mine = scope === "my" && stringNotEmpty(currentUserUuid) ? [currentUserUuid] : undefined;
  if (filters.reporter?.type === FilterType.options) {
    return (filters.reporter.value ?? [])
      .map((v) => v.value)
      .concat(mine != null ? mine : [])
      .filter(notNullOrUndefined);
  }

  return mine;
};
export const translateAssignee = (scope: RequestPageScope, filters: RequestFiltersResult, currentUserUuid?: string) => {
  const assignedToMe = scope === "assigned" && stringNotEmpty(currentUserUuid) ? [currentUserUuid] : undefined;
  if (filters.assignee?.type === FilterType.options) {
    return (filters.assignee.value ?? [])
      .map((v) => v.value)
      .concat(assignedToMe != null ? assignedToMe : [])
      .filter(notNullOrUndefined);
  }

  return assignedToMe;
};
export const translateUnassigned = (activeTab: ActiveTab) => {
  return undefined;
};
export const translateScope = (scope: RequestPageScope) => {
  return scope === "my" ? ReqMgtRequestsFilterScope.My : undefined;
};
