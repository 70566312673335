import React from "react";

import { RequestTabs } from "../../components/RequestTabs";
import { RequestsMenuProvider } from "../../contexts/RequestsMenuContext";

import { RequestsFilter } from "./components/RequestsFilter";
import { RequestsList } from "./components/RequestsList";
import { RequestsAccessProvider } from "./contexts/RequestsAccessContext";
import { RequestsFilterProvider } from "./contexts/RequestsFilterContext";
import { RequestsScopeProvider } from "./contexts/RequestsScopeContext";
import { RequestsStatusesProvider } from "./contexts/RequestsStatusesContext";

export const Requests: React.FC = () => {
  return (
    <RequestsMenuProvider>
      <RequestsAccessProvider>
        <RequestsScopeProvider>
          <RequestsStatusesProvider>
            <RequestTabs>
              <RequestsFilterProvider>
                <RequestsFilter className="pb-4" />
                <RequestsList />
              </RequestsFilterProvider>
            </RequestTabs>
          </RequestsStatusesProvider>
        </RequestsScopeProvider>
      </RequestsAccessProvider>
    </RequestsMenuProvider>
  );
};
