import type { ReactNode } from "react";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { CurrentRole, Role, stringNotEmpty, UrlParams, useSiteContext } from "@equiem/lib";
import { useDebounced } from "@equiem/react-admin-ui";

import type { VisitorAppointmentsQueryVariables } from "../../../generated/visitors-client";
import type { AppointmentFiltersResult } from "../utils/mapping";
import { transformToQuery } from "../utils/mapping";

export type PredefinedTabFilters = "my" | "all";

export const tabsTypes: PredefinedTabFilters[] = ["my", "all"];
export const endUserTabsTypes: PredefinedTabFilters[] = ["my"];

export interface AppointmentsFilterContext {
  filters: AppointmentFiltersResult;
  visitorAppointmentsFilters: VisitorAppointmentsQueryVariables;
  setFilters?: (filters: AppointmentFiltersResult) => void;
  search?: string;
  debouncedSearch?: string;
  setSearch?: (search: string) => void;
  selectedTab: PredefinedTabFilters;
  setSelectedTab?: (tab: PredefinedTabFilters) => void;
}

export const AppointmentsFilterContext = createContext<AppointmentsFilterContext>({
  filters: {},
  visitorAppointmentsFilters: {},
  setFilters: undefined,
  search: undefined,
  debouncedSearch: undefined,
  setSearch: undefined,
  selectedTab: tabsTypes[0],
  setSelectedTab: undefined,
});

export const AppointmentsFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const searchFromQueryParams = router.query.search as string | undefined;
  const { setParam, deleteParam } = useContext(UrlParams);
  const { currentRole } = useContext(CurrentRole);
  const { uuid } = useSiteContext();
  const [filters, setFilters] = useState<AppointmentFiltersResult>({});
  const [search, setSearch] = useState<string>(searchFromQueryParams ?? "");
  const defaultTab = currentRole === Role.Unknown ? endUserTabsTypes[0] : tabsTypes[0];
  const [selectedTab, setSelectedTab] = useState<PredefinedTabFilters>(
    router.query.tab != null && router.query.tab.length > 0 ? (router.query.tab as PredefinedTabFilters) : defaultTab,
  );
  const debouncedSearch = useDebounced(search, 500);
  const debouncedFilters = useDebounced(filters, 500);

  // react on tab reset
  useEffect(() => {
    if (router.query.tab === undefined) {
      setSelectedTab(defaultTab);
    }
  }, [router.query.tab]);

  // react on tab selection change
  useEffect(() => {
    if (router.query.tab !== selectedTab) {
      setParam("tab", selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (stringNotEmpty(debouncedSearch)) {
      if (debouncedSearch !== router.query.search) {
        setParam("search", debouncedSearch);
      }
    } else if (router.query.search != null) {
      deleteParam("search");
    }
  }, [debouncedSearch]);

  const visitorAppointmentsFilters: VisitorAppointmentsQueryVariables = useMemo(() => {
    const transformedFilters = transformToQuery(debouncedFilters, debouncedSearch, selectedTab, currentRole);

    return {
      ...transformedFilters,
    };
  }, [debouncedSearch, debouncedFilters, selectedTab, uuid]);

  return (
    <AppointmentsFilterContext.Provider
      value={{
        filters,
        visitorAppointmentsFilters,
        setFilters,
        search,
        debouncedSearch,
        setSearch,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </AppointmentsFilterContext.Provider>
  );
};
