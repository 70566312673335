import { DateTime } from "luxon";

import { Role } from "@equiem/lib";
import type { FilterValue } from "@equiem/react-admin-ui";
import { FilterDateModifier, FilterIsModifier, FilterType } from "@equiem/react-admin-ui";

import type { VisitorAppointmentsQueryVariables } from "../../../generated/visitors-client";
import { VisitorAppointmentsQueryRole } from "../../../generated/visitors-client";

export type AppointmentFilterType = "date" | "location" | "company" | "recurring" | "visitorType";
export type AppointmentFiltersResult = Partial<Record<AppointmentFilterType, FilterValue>>;

const DATE_FORMAT = "yyyy-LL-dd";

export const translateBuilding = (filters: AppointmentFiltersResult): string[] | undefined => {
  if (filters.location?.type === FilterType.options) {
    const values = filters.location.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }
  return undefined;
};

export const translateCompany = (filters: AppointmentFiltersResult): string[] | undefined => {
  if (filters.company?.type === FilterType.options) {
    const values = filters.company.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }
  return undefined;
};

export const translateVisitorType = (filters: AppointmentFiltersResult): string[] | undefined => {
  if (filters.visitorType?.type === FilterType.options) {
    const values = filters.visitorType.value ?? [];
    if (values.length > 0) {
      return values.map((v) => v.value);
    }
  }
  return undefined;
};

export const translateDate = (
  filters: AppointmentFiltersResult,
): { beginDate: number; endDate: number } | undefined => {
  if (filters.date?.type === FilterType.date) {
    if (filters.date.modifier === FilterDateModifier.is && filters.date.value != null) {
      return {
        beginDate: DateTime.fromFormat(filters.date.value as string, DATE_FORMAT)
          .startOf("day")
          .toMillis(),
        endDate: DateTime.fromFormat(filters.date.value as string, DATE_FORMAT)
          .endOf("day")
          .toMillis(),
      };
    }
    if (filters.date.modifier === FilterDateModifier.between && filters.date.value?.[0] != null) {
      return {
        beginDate: DateTime.fromFormat(filters.date.value[0] as string, DATE_FORMAT)
          .startOf("day")
          .toMillis(),
        endDate: DateTime.fromFormat(filters.date.value[1] as string, DATE_FORMAT)
          .endOf("day")
          .toMillis(),
      };
    }
  }
  return undefined;
};

export const translateAsRole = (tabs: "all" | "my", currentRole: Role) => {
  if (tabs === "all") {
    return currentRole === Role.PropertyManager
      ? VisitorAppointmentsQueryRole.PropertyManager
      : currentRole === Role.WorkplaceManager
      ? VisitorAppointmentsQueryRole.WorkplaceManager
      : undefined;
  }

  return undefined;
};

export const translateIsRecurring = (filters: AppointmentFiltersResult) => {
  if (filters.recurring?.type === FilterType.is && filters.recurring.value != null) {
    return filters.recurring.value === FilterIsModifier.is;
  }

  return undefined;
};

export const transformToQuery = (
  filters: AppointmentFiltersResult,
  search: string,
  tabs: "all" | "my",
  currentRole: Role,
): Partial<VisitorAppointmentsQueryVariables> => {
  const resultFilters: Partial<VisitorAppointmentsQueryVariables> = {};
  const dateFilters = translateDate(filters);

  if (dateFilters != null) {
    resultFilters.beginDate = dateFilters.beginDate;
    resultFilters.endDate = dateFilters.endDate;
  }

  resultFilters.buildingUuids = translateBuilding(filters);
  resultFilters.hostCompanyUuids = translateCompany(filters);
  resultFilters.search = search;
  resultFilters.as = translateAsRole(tabs, currentRole);
  resultFilters.isRecurring = translateIsRecurring(filters);
  resultFilters.visitorTypeUuids = translateVisitorType(filters);

  return resultFilters;
};
