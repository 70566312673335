import React, { useContext } from "react";

import type { ComponentProps } from "react";
import { FormComboBoxContext } from "../FormComboBoxContext";
import { useTheme } from "../../../../contexts/Theme";

export const FormComboBoxMenu: React.FC<
  ComponentProps<"div"> & {
    noBorder?: boolean;
    noShadow?: boolean;
    height?: string;
    maxHeight?: string;
    showWhenEmpty?: boolean;
  }
> = ({
  children,
  className = "",
  noBorder = false,
  noShadow = false,
  height,
  maxHeight,
  showWhenEmpty = false,
  ...props
}) => {
  const { focused, floating, items } = useContext(FormComboBoxContext);
  const { colors, borderRadius } = useTheme();

  return (
    <>
      {(items.length === 0 && !showWhenEmpty) || !focused ? null : (
        <div {...props} className={className} ref={floating?.floating}>
          {children}
        </div>
      )}
      <style jsx>{`
        div {
          position: ${floating?.strategy ?? "absolute"};
          left: ${floating?.x ?? 0}px;
          top: ${floating?.y ?? 0}px;
          background: ${colors.white};
          border: ${!noBorder ? `1px solid ${colors.border}` : ""};
          border-radius: ${borderRadius};
          box-shadow: ${noShadow ? "none" : "0px 8px 24px rgba(0, 0, 0, 0.25)"};
          height: ${height ?? "auto"};
          max-height: ${maxHeight ?? "none"};
          overflow: ${height != null || maxHeight != null ? "auto" : "visible"};
          z-index: 2;
        }
      `}</style>
    </>
  );
};
