import * as yup from "yup";

import type { TFunction } from "@equiem/localisation-eq1";

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t("settings.editBuilding.nameRequired")),
    occupants: yup.number().max(1000000, t("settings.editBuilding.occupantsMax")),
    area: yup.number().max(10000000, t("settings.editBuilding.areaMax")),
    address: yup.mixed().when("addressStatus", {
      is: "enabled",
      then: yup.object().shape({
        address: yup.string().required(t("settings.editBuilding.addressRequired")),
      }),
    }),
  });

export const getSpaceValidationSchema = (t: TFunction, canManageRegion: boolean) =>
  yup.object().shape({
    title: yup.string().required(t("settings.build.widget.nameRequired")),
    ownerCompanyUuid: canManageRegion ? yup.string().required() : yup.string(),
  });
