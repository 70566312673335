/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";
import { BaseTimeRange } from "./BaseTimeRange";
import { TimeRangeCaption } from "./TimeRangeCaption";
import type { ResourceCatalogueQuery } from "../../../../generated/gateway-client";

type BookableResourcesListItem = ResourceCatalogueQuery["myResourceCatalogue"][number];

const calculateGridColumnSpans = (totalColumns: number, columnSize: number) => {
  switch (totalColumns) {
    case 1:
      return [{ gridColumnStart: 1, gridColumnEnd: 7 }];
    case 2:
      return [
        { gridColumnStart: 1, gridColumnEnd: columnSize === 1 ? 4 : 5 },
        { gridColumnStart: columnSize === 1 ? 4 : 3, gridColumnEnd: 7 },
      ];
    case 3:
      return [
        { gridColumnStart: 1, gridColumnEnd: 3 },
        { gridColumnStart: 3, gridColumnEnd: 5 },
        { gridColumnStart: 5, gridColumnEnd: 7 },
      ];
    default:
      return [];
  }
};

export const AvailableTimeSlot: React.FC<{
  start: number;
  end: number;
  overflowAvailabilities: number;
  columnNum: number;
  columnSize: number;
  totalColumns: number;
  timezone: string;
  resource: BookableResourcesListItem;
  notBookable: boolean;
  setReloadUuid: (_uuid: string) => void;
}> = ({
  start,
  end,
  overflowAvailabilities,
  columnNum,
  columnSize,
  totalColumns,
  timezone,
  resource,
  notBookable,
  setReloadUuid,
}) => {
  const { gridColumnStart, gridColumnEnd } = calculateGridColumnSpans(totalColumns, columnSize)[columnNum];
  return (
    <BaseTimeRange
      start={start}
      end={end}
      overflowAvailabilities={overflowAvailabilities}
      timezone={timezone}
      className="active-time-slot available-time-slot"
      resource={resource}
      notBookable={notBookable}
      isSlot={true}
      setReloadUuid={setReloadUuid}
      style={{ gridColumnStart, gridColumnEnd }}
    >
      <TimeRangeCaption
        startTime={start}
        endTime={end}
        timezone={timezone}
        totalColumns={totalColumns}
        overflowAvailabilities={overflowAvailabilities}
      />
    </BaseTimeRange>
  );
};
