import React, { useContext } from "react";
import { Site, useDisabledSiteSwitcher } from "@equiem/lib";
import { EmptyState, ProgressCircle, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import { BookingsTab } from "../../components/BookingsTab";
import { StickyHeaderTable } from "../../components/StickyHeaderTable";
import { GenerateReportButton } from "./components/GenerateReportButton";
import {
  reconciliationReportTableRowHeaders,
  ReconciliationReportTotalsRow,
  ReconciliationReportTableRow,
} from "./components/ReconciliationReportTable";
import { useReportData } from "./hooks/useReportData";
import type { Report } from "./utils/ReportParams";
import { downloadReconciliationReport } from "./utils/reconciliationReport";
import { downloadYardiExport } from "./utils/yardiExport";

export const BookingReports: React.FC = () => {
  const { t } = useTranslation();
  const { timezone } = useContext(Site);
  const theme = useTheme(true);

  if (process.env.bookingMultiSiteEnabled === "true") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDisabledSiteSwitcher();
  }

  const {
    filterItems,
    setFilterValues,

    error,
    loading,

    bookings,
    totals,
    currencyCode,

    reportParams,
    yardiEnabled,
  } = useReportData();

  if (error != null) {
    return <div>{error.message}</div>;
  }

  const onGenerateCsv = (report: Report) => {
    if (report === "yardi") {
      downloadYardiExport(reportParams);
    } else {
      downloadReconciliationReport(reportParams);
    }
  };

  return (
    <>
      <BookingsTab
        filters={{ items: filterItems, setValues: setFilterValues }}
        button={<GenerateReportButton yardiEnabled={yardiEnabled} onClick={onGenerateCsv} />}
      >
        <div className="pb-5 px-7">
          {loading && bookings.length === 0 && (
            <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
              <ProgressCircle size="md" />
              <span className="ml-4">{`${t("common.loading")}...`}</span>
            </div>
          )}
          {bookings.length > 0 && !loading && (
            <StickyHeaderTable loading={loading} rowHeaders={reconciliationReportTableRowHeaders(t)}>
              <tr className="header-row">
                <td colSpan={reconciliationReportTableRowHeaders(t).length}>
                  <h2 className="my-2">{t("bookings.reports.summary")}</h2>
                </td>
              </tr>
              <ReconciliationReportTotalsRow
                numBookings={bookings.length}
                totals={totals}
                currencyCode={currencyCode}
              />
              <tr className="header-row">
                <td colSpan={reconciliationReportTableRowHeaders(t).length}>
                  <h2 className="my-2">{t("bookings.reports.detail")}</h2>
                </td>
              </tr>
              {bookings.map((booking) => (
                <ReconciliationReportTableRow
                  key={booking.uuid}
                  booking={booking}
                  currencyCode={currencyCode}
                  timezone={timezone}
                />
              ))}
            </StickyHeaderTable>
          )}
          {bookings.length === 0 && !loading && (
            <div className="w-100 mt-6 mb-6">
              <EmptyState />
            </div>
          )}
        </div>
      </BookingsTab>
      <style jsx>{`
        .page-title {
          font-weight: bold;
        }
        .filter {
          gap: ${theme.spacers.s6};
        }
        .filter :global(.filter-group) {
          width: unset;
        }
        .filter :global(.company) {
          width: unset;
          color: inherit;
        }
      `}</style>
    </>
  );
};
