import { SubTopMenu, useDisabledSiteSwitcher } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Text, useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import { RiArrowLeftLine } from "@equiem/react-admin-ui/icons";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { ResourceCreateForm } from "./components/create-and-edit/ResourceCreateForm";

export const ResourceCreateNew: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { breakpoints, colors, spacers } = useTheme(true);
  const { width } = useWindowDimensions();

  if (process.env.bookingMultiSiteEnabled === "true") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDisabledSiteSwitcher();
  }

  const isMobile = width <= breakpoints.md;

  return (
    <>
      <Head>
        <title>{t("bookings.resources.newResource")}</title>
      </Head>
      <div className="cont">
        {isMobile ? (
          <div className="mobile-header">
            <Button
              variant="ghost"
              size="md"
              color={colors.dark}
              className="back"
              shape="round"
              onClick={() => {
                router.back();
              }}
              aria-label={t("common.back")}
            >
              <RiArrowLeftLine size={20} color={colors.dark} />
            </Button>
            <Text variant="heading" size="small">
              {t("bookings.resources.addNewResource")}
            </Text>
          </div>
        ) : (
          <SubTopMenu btmPadding={false}>
            <h1 className="mb-3 font-weight-bold">{t("bookings.resources.addNewResource")}</h1>
          </SubTopMenu>
        )}
        <ResourceCreateForm />
      </div>
      <style jsx>{`
        .cont {
          background: ${colors.white};
          min-height: calc(100vh - 65px);
        }
        .mobile-header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: ${spacers.s4} ${spacers.s5};
          gap: ${spacers.s3};
          background: white;
          border-bottom: 1px solid ${colors.grayscale[10]};
          position: sticky;
          top: 64px;
          z-index: 2;
        }
        .mobile-header :global(h1) {
          line-height: 32px !important;
          text-align: center;
        }
      `}</style>
    </>
  );
};
