import React from "react";
import { useSiteContext } from "@equiem/lib";
import { Form } from "@equiem/react-admin-ui";
import { Field, useFormikContext } from "formik";
import { useRouter } from "next/router";

import { useResourceCatalogueQuery } from "../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";

type FormValuesType = { resources: string[] };

interface Props {
  className?: string;
  fieldName?: string;
  showErrorCheck?: boolean;
}

export const ResourceSelector: React.FC<Props> = ({
  className = "",
  fieldName = "resources",
  showErrorCheck = true,
}) => {
  const { t } = useTranslation();
  const fm = useFormikContext<FormValuesType>();
  const router = useRouter();
  const site = useSiteContext();
  const { data, loading, error } = useResourceCatalogueQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: { siteUuids: [site.uuid] },
      permissionFilters: { canObserveBookings: true },
    },
  });

  if (error != null) {
    return <div>{error.message}</div>;
  }

  const thisResourceUuid = router.query.uuid as string;
  const options = (data?.myResourceCatalogue ?? [])
    .filter((resource) => resource.uuid !== thisResourceUuid)
    .map((resource) => ({ value: resource.uuid, label: resource.name }));

  const formError =
    fm.errors.resources == null
      ? undefined
      : Array.isArray(fm.errors.resources)
      ? fm.errors.resources.join(", ")
      : fm.errors.resources;

  return (
    <Form.Group
      className={className}
      label={t("bookings.settings.resources")}
      required
      error={showErrorCheck ? formError : undefined}
    >
      <Field
        name={fieldName}
        className="resources"
        options={options}
        as={Form.MultiSelect}
        variant="wrap"
        placeholder={t("bookings.settings.searchForResources")}
        isMulti
        enableSelectAll
        disabled={loading || fm.isSubmitting}
      />
    </Form.Group>
  );
};
