/* eslint-disable @typescript-eslint/no-type-alias */
import type { IconType } from "react-icons";

export enum FilterOptionsModifier {
  includes = "includes",
  excludes = "excludes",
  empty = "empty",
}

export enum FilterDateModifier {
  is = "is",
  between = "between",
  before = "before",
  after = "after",
}

export enum FilterIsModifier {
  is = "is",
  isnt = "isnt",
  empty = "empty",
}

export enum FilterTimeModifier {
  is = "is",
  between = "between",
  before = "before",
  after = "after",
}

export enum FilterDurationModifier {
  is = "is",
  greater = "greater",
  less = "less",
  between = "between",
}

export enum FilterNumberModifier {
  is = "is",
  greater = "greater",
  less = "less",
  between = "between",
}

export enum FilterTextModifier {
  includes = "includes",
}

export enum FilterSelectModifier {
  is = "is",
}

export enum FilterDateMonthModifier {
  is = "is",
  between = "between",
  before = "before",
  after = "after",
}

export enum FilterType {
  datemonth = "datemonth",
  date = "date",
  time = "time",
  duration = "duration",
  number = "number",
  infiniteOptions = "infinite-options",
  options = "options",
  text = "text",
  select = "select",
  is = "is",
}

export type FilterValueDate = {
  type: FilterType.date;
  modifier: FilterDateModifier.is | FilterDateModifier.before | FilterDateModifier.after;
  value?: string;
};

export type FilterValueDateBetween = {
  type: FilterType.date;
  modifier: FilterDateModifier.between;
  value?: [string, string];
};

export type FilterValueDateMonth = {
  type: FilterType.datemonth;
  modifier: FilterDateMonthModifier.is | FilterDateMonthModifier.before | FilterDateMonthModifier.after;
  value?: string;
};

export type FilterValueDateMonthBetween = {
  type: FilterType.datemonth;
  modifier: FilterDateMonthModifier.between;
  value?: [string, string];
};

type FilterValueTime = {
  type: FilterType.time;
  modifier: FilterTimeModifier.is | FilterTimeModifier.before | FilterTimeModifier.after;
  value?: string;
};

type FilterValueTimeBetween = {
  type: FilterType.time;
  modifier: FilterTimeModifier.between;
  value?: [string, string];
};

type FilterValueDuration = {
  type: FilterType.duration;
  modifier: FilterDurationModifier.is | FilterDurationModifier.greater | FilterDurationModifier.less;
  value?: string;
};

type FilterValueDurationBetween = {
  type: FilterType.duration;
  modifier: FilterDurationModifier.between;
  value?: [string, string];
};

type FilterValueNumber = {
  type: FilterType.number;
  modifier: FilterNumberModifier.is | FilterNumberModifier.greater | FilterNumberModifier.less;
  value?: string;
};

type FilterValueNumberBetween = {
  type: FilterType.number;
  modifier: FilterNumberModifier.between;
  value?: [string, string];
};

export type FilterValueOptions = {
  type: FilterType.options;
  modifier: FilterOptionsModifier.includes | FilterOptionsModifier.excludes | FilterOptionsModifier.empty;
  value?: FilterOption[];
  search?: string;
};

export type FilterValueInfiniteOptions = {
  type: FilterType.infiniteOptions;
  modifier: FilterOptionsModifier.includes | FilterOptionsModifier.excludes | FilterOptionsModifier.empty;
  value?: FilterOption[];
  search?: string;
};

type FilterValueText = {
  type: FilterType.text;
  value?: string;
  modifier: FilterTextModifier.includes;
};

type FilterValueSelect = {
  type: FilterType.select;
  value?: string;
  modifier: FilterSelectModifier.is;
};

type FilterValueIs = {
  type: FilterType.is;
  value?: string;
  modifier: FilterIsModifier.is | FilterIsModifier.isnt | FilterIsModifier.empty;
};

export type FilterValue =
  | FilterValueDate
  | FilterValueDateMonth
  | FilterValueDateMonthBetween
  | FilterValueDateBetween
  | FilterValueTime
  | FilterValueTimeBetween
  | FilterValueDuration
  | FilterValueDurationBetween
  | FilterValueNumber
  | FilterValueNumberBetween
  | FilterValueOptions
  | FilterValueInfiniteOptions
  | FilterValueText
  | FilterValueSelect
  | FilterValueIs
  | undefined;

type FilterItemBase = {
  title: string;
  icon?: IconType;
  disabled?: boolean;
};

export type FilterOption = {
  label: string;
  value: string;
};

export type FilterOptionWithSearch = FilterOption & {
  searchKeywords?: string[];
};

export type FilterItemDate = FilterItemBase & {
  type: "date";
  modifiers?: FilterDateModifier[];
  min?: string;
  max?: string;
};

export type FilterItemDateMonth = FilterItemBase & {
  type: "datemonth";
  modifiers?: FilterDateMonthModifier[];
  min?: string;
  max?: string;
};

export type FilterItemTime = FilterItemBase & {
  type: "time";
  modifiers?: FilterTimeModifier[];
  diffMinutes?: number;
};

export type FilterItemDuration = FilterItemBase & {
  type: "duration";
  modifiers?: FilterDurationModifier[];
};

export type FilterItemNumber = FilterItemBase & {
  type: "number";
  modifiers?: FilterNumberModifier[];
};

export type FilterItemOptions = FilterItemBase & {
  type: "options" | "infinite-options";
  loading?: boolean;
  hasMoreItems?: boolean;
  modifiers?: FilterOptionsModifier[];
  options: FilterOptionWithSearch[];
  fetchMore?: () => void;
  searchCallback?: (search: string) => Promise<unknown>;
};

export type FilterItemText = FilterItemBase & {
  type: "text";
  modifiers?: FilterTextModifier[];
  multiline?: boolean;
  placeholder?: string;
};

export type FilterItemSelect = FilterItemBase & {
  type: "select";
  modifiers?: FilterSelectModifier[];
  options: FilterOption[];
};

export type FilterIsSelect = FilterItemBase & {
  type: "is";
  modifiers?: FilterIsModifier[];
};

export type FilterItem =
  | FilterItemDate
  | FilterItemDateMonth
  | FilterItemDuration
  | FilterItemTime
  | FilterItemNumber
  | FilterItemOptions
  | FilterItemText
  | FilterItemSelect
  | FilterIsSelect;

export type FilterItemProps<T extends FilterItem> = Omit<T, "type"> & {
  name: string;
};

export interface HTMLDateInputElement extends HTMLInputElement {
  showPicker: () => void;
}
