import type { TFunction } from "@equiem/localisation-eq1";
import type { TabItem } from "@equiem/react-admin-ui";

export type CategoryTabKey = "general" | "integrations";

interface ProfileTab extends TabItem {
  key: CategoryTabKey;
}

export const editCategoryTabs = (t: TFunction): ProfileTab[] => [
  {
    title: t("settings.notifications.general"),
    key: "general",
  },
  {
    title: t("settings.editProfile.integrations"),
    key: "integrations",
  },
];
