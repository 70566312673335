import { DateTime, Duration } from "luxon";

import { BookableResourcePaymentMethod } from "../../../generated/gateway-client";

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatDuration = (hours: number) => Duration.fromObject({ hours }).toFormat("hh:mm");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatDateTime = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toISO() ?? "";

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatIsoDate = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toFormat("yyyy-MM-dd");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatUSDate = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toFormat("MM/dd/yyyy");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatUSShortDate = (timestamp: number, timezone: string) =>
  DateTime.fromMillis(timestamp, { zone: timezone }).toFormat("MM/dd/yy");

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatPaymentMethod = (paymentMethod: BookableResourcePaymentMethod | null | undefined): string => {
  if (paymentMethod === BookableResourcePaymentMethod.CreditCard) {
    return "Credit Card";
  }
  return paymentMethod ?? "";
};

/** NOTE: Unlocalised formatter! Only for use in CSV exports. */
export const formatCurrency = (amount: number, currency: string) => {
  const formatter = new Intl.NumberFormat("en", { style: "currency", currencyDisplay: "narrowSymbol", currency });
  return formatter.format(amount / 100);
};
