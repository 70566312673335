import Link from "next/link";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLogoBackground } from "../../hooks/useLogoBackground";
import { Burger } from "./sidemenu/Burger";
import { CurrentProfile, Site, stringNotEmpty } from "@equiem/lib";
import { Tooltip, useConfirmer, useTheme } from "@equiem/react-admin-ui";
import { SideMenu } from "./sidemenu/SideMenu";
import { useTranslation } from "@equiem/localisation-eq1";
import { useRouter } from "next/router";
import { APP_MODE_PARAM_NAME } from "../../lib/auth/cookies/constants";
import { EquiemOneLogo } from "../icons/EquiemOneLogo";

export const SiteNavbar: React.FC = () => {
  const site = useContext(Site);
  const { withConfirmation } = useConfirmer();
  const { ready: translationsReady, t } = useTranslation();
  const { whiteBg, bgColor, fgColor } = useLogoBackground();
  const { breakpoints, zIndexes } = useTheme();
  const { canManageCurrentSite } = useContext(CurrentProfile);
  const router = useRouter();
  const size = site.logoSize === "m" ? 12 : site.logoSize === "l" ? 14 : 11;
  const msize = site.logoSize === "m" ? 9 : site.logoSize === "l" ? 11 : 8;
  const [isClientSide, setIsClientSide] = useState(false);

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  const defaultLink = useMemo(() => {
    if (!isClientSide) {
      return "";
    }
    const url = new URL(router.asPath, window.location.origin);
    url.searchParams.set(APP_MODE_PARAM_NAME, "default");
    return url.toString();
  }, [isClientSide, router]);

  return (
    <div className="site-nav-cont">
      <div className={`site-nav d-flex align-items-center ${whiteBg ? " border-bottom shadow-sm" : ""}`}>
        {stringNotEmpty(site.logo) ? (
          <Link href={`https://${site.webAppUrl}`} passHref legacyBehavior>
            <a className="logo-container d-block h-100">
              <img src={site.logo} />
            </a>
          </Link>
        ) : null}
        {translationsReady && (
          <div className="d-flex justify-content-end align-items-center ml-auto">
            {isClientSide && canManageCurrentSite && (
              <Tooltip title={t("webng.returnToEquiemOne.tooltip")} showArrow>
                <a
                  className="return-to-eq1 mr-4"
                  style={{ cursor: "pointer" }}
                  onClick={withConfirmation({
                    confirmButtonText: t("webng.returnToEquiemOne.confirmButtonText"),
                    message: t("webng.returnToEquiemOne.confirmMessage"),
                    title: t("webng.returnToEquiemOne.confirmTitle"),
                    confirmButtonVariant: "primary",
                    onConfirm: () => {
                      window.location.href = defaultLink;
                    },
                  })}
                >
                  <EquiemOneLogo />
                </a>
              </Tooltip>
            )}
            <Burger className="open-side-menu ml-2 ml-md-3" />
          </div>
        )}
      </div>
      <SideMenu />
      <style jsx>{`
        .logo-container {
          height: 100%;
          display: block;
          max-width: ${msize}rem;
        }
        .logo-container img {
          max-height: 6rem;
          max-width: 100%;
          flex-shrink: 0;
        }
        @media (min-width: ${breakpoints.md}px) {
          .logo-container {
            max-width: ${size}rem;
          }
        }
        .site-nav-cont {
          position: sticky;
          top: 0;
          z-index: ${zIndexes.modal - 1} !important;
        }
        .site-nav {
          background: ${bgColor};
          color: ${fgColor} !important;
          padding: 8px;
        }
        .site-nav :global(a) {
          color: inherit;
        }
        @media (min-width: ${breakpoints.md}px) {
          .site-nav {
            padding: 8px 32px;
            min-height: 72px;
          }
        }
      `}</style>
    </div>
  );
};
