import { stringIsEmpty, useQueryState, useSiteContext, useDisabledSiteSwitcher } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem, RadioIconOption } from "@equiem/react-admin-ui";
import { RadioIcons, EmptyState, Text, useTheme } from "@equiem/react-admin-ui";
import { RiCalendar2Line, RiEyeLine, RiListCheck } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CatalogueCalendar } from "./components/calendar-view/CatalogueCalendar";
import { CatalogueCalendarNavigation } from "./components/calendar-view/CatalogueCalendarNavigation";
import { CatalogueCard, CatalogueCardLoading } from "./components/CatalogueCard";
import { useResourceFilters } from "./hooks/useResourceFilters";
import { BookingCreateNewModal } from "../operations/components/BookingCreateNewModal";
import { BookingsTab } from "../../components/BookingsTab";
import { withContexts } from "../../contexts/withContexts";
import { BookableResourceStatus, useResourceCatalogueQuery } from "../../generated/gateway-client";
import { useRouter } from "next/router";
import { BookingModal } from "../operations/contexts/BookingModalContext";
import { CatalogueMetrics } from "./components/CatalogueMetrics";
import { CatalogueView as View } from "./models/CatalogueView";

const isView = (v: unknown): v is View => Object.values(View).includes(v as View);

const EmptyCatalogue = () => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();

  return (
    <>
      <div className="empty-catalogue-icon">
        <RiEyeLine size="24" color={colors.transparent.black[40]} />
      </div>
      <Text variant="text" size="small" color={colors.transparent.black[40]} className="my-0 text-center">
        {t("bookings.resources.catalogueCurrentlyEmpty")}
      </Text>
      <style jsx>{`
        .empty-catalogue-icon {
          display: flex;
          padding: ${spacers.s5};
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          background: ${colors.transparent.black[5]};
        }
      `}</style>
    </>
  );
};

const BrowseCatalogueLocal = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const { uuid: siteUuid, timezone } = useSiteContext();
  const { breakpoints, spacers } = useTheme();

  if (process.env.bookingMultiSiteEnabled === "true") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDisabledSiteSwitcher();
  }

  const viewOptions: Array<RadioIconOption<View>> = [
    {
      icon: RiListCheck,
      value: View.LIST,
      className: View.LIST,
      tooltipText: t("bookings.operations.list"),
    },
    {
      icon: RiCalendar2Line,
      value: View.CALENDAR,
      className: View.CALENDAR,
      tooltipText: t("bookings.operations.calendar"),
    },
  ];

  const [{ view }, setQueryState] = useQueryState({
    initial: { view: View.LIST },
    parse: {
      view: (v) => (isView(v) ? v : View.LIST),
    },
    clearQueryOnChange: true,
    rememberLastState: true,
  });

  const [searchText, setSearchText] = useState("");
  const {
    loading: filtersLoading,
    filterOptions,
    filterValues,
    filters: { managedBy, ...filters },
    onFilterChange,
  } = useResourceFilters({ canManageBookings: true, isSegmentedToViewer: true });

  // exclude date from filters for calendar view
  const items =
    view === View.CALENDAR
      ? Object.keys(filterOptions).reduce<Record<string, FilterItem>>((acc, key) => {
          if (key !== "date") {
            acc[key] = filterOptions[key];
          }
          return acc;
        }, {})
      : filterOptions;

  const complexFilters = useMemo(
    () => ({
      loading: filtersLoading,
      items,
      initialValues: filterValues,
      setValues: onFilterChange,
    }),
    [filterValues, filtersLoading, items, onFilterChange],
  );

  const { data, loading, error, called } = useResourceCatalogueQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: {
        ...filters,
        status: BookableResourceStatus.Published,
      },
      permissionFilters: { canManageBookings: true, isSegmentedToViewer: true },
    },
  });

  const resourcesToShow = useMemo(() => {
    const managedByMe = managedBy === "me";
    const searchFor = stringIsEmpty(searchText) ? null : searchText.trim().toLowerCase();

    return filtersLoading || data?.myResourceCatalogue == null
      ? []
      : data.myResourceCatalogue
          .filter((resource) => {
            // TODO(ianm): temporary front-end filtering so I can remove the back-end filtering for multi-site support
            return resource.viewerPermissions?.isSegmentedToViewer === true || resource.destination.uuid === siteUuid;
          })
          .filter((resource) => {
            const canManageBookings = resource.viewerPermissions?.canManageBookings ?? false;
            return (
              (searchFor == null || resource.name.toLowerCase().includes(searchFor)) &&
              (!managedByMe || canManageBookings)
            );
          });
  }, [data?.myResourceCatalogue, filtersLoading, managedBy, searchText, siteUuid]);

  // Open/close resource side modal based on query param.
  const router = useRouter();
  const modal = useContext(BookingModal);
  useEffect(() => {
    if (typeof router.query.resource === "string") {
      modal.open(router.query.resource);
    } else if (modal.id != null && view !== View.CALENDAR) {
      modal.close();
    }
  }, [router.query.resource, modal, view]);

  return (
    <>
      <BookingsTab
        title={
          <RadioIcons
            options={viewOptions}
            value={view}
            onChange={(newView: View) => setQueryState({ view: newView })}
          />
        }
        navigation={
          view === View.CALENDAR && (
            <CatalogueCalendarNavigation
              startDate={filters.date ?? DateTime.local({ zone: timezone }).startOf("day").toMillis()}
            />
          )
        }
        search={{ searchText, setSearchText }}
        filters={complexFilters}
      >
        <CatalogueMetrics view={view} readyToSend={called && !loading}>
          {loading || error != null || resourcesToShow.length > 0 ? (
            <>
              {error != null && <div className="ml-6">{tError(error)}</div>}
              {view === View.LIST && (
                <div className="listing">
                  {loading && Array.from({ length: 6 }).map((_, index) => <CatalogueCardLoading key={index} />)}
                  {!loading &&
                    error == null &&
                    resourcesToShow.length > 0 &&
                    resourcesToShow.map((resource) => <CatalogueCard key={resource.uuid} resource={resource} />)}
                </div>
              )}
              {view === View.CALENDAR && error == null && (
                <CatalogueCalendar
                  timezone={timezone}
                  resources={resourcesToShow}
                  resourcesLoading={loading}
                  startDate={filters.date ?? DateTime.local({ zone: timezone }).startOf("day").toMillis()}
                />
              )}
            </>
          ) : (
            <div className="empty-listing">
              {data?.myResourceCatalogue != null && data.myResourceCatalogue.length > 0 ? (
                <EmptyState />
              ) : (
                <EmptyCatalogue />
              )}
            </div>
          )}
        </CatalogueMetrics>
      </BookingsTab>
      <BookingCreateNewModal />
      <style jsx>{`
        .listing {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
          justify-items: center;
          grid-gap: ${spacers.s7} ${spacers.s5};
          padding: ${spacers.s4} ${spacers.s7} ${spacers.s7};
        }
        .empty-listing {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
          padding: ${spacers.s7};
          width: 100%;
          align-items: center;
          align-self: center;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .listing {
            grid-template-columns: 1fr;
          }
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .listing {
            padding: ${spacers.s5};
          }
        }
      `}</style>
    </>
  );
};

export const BrowseCatalogue = withContexts(BrowseCatalogueLocal);
