/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";
import { StickyElement, useTheme } from "@equiem/react-admin-ui";
import { useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { DateTime } from "luxon";

import type { SiteCalendarBookingFragment } from "../../../generated/gateway-client";
import type { SiteCalendarDay } from "../../../hooks/useMultiDayBookingsCalendar";

interface Props {
  calendar: SiteCalendarDay[];
  onDateClicked: (date: number) => unknown;
  onBookingClicked: (booking: SiteCalendarBookingFragment) => unknown;
}

interface DayProps {
  day: SiteCalendarDay;
  selectedMonth: number;
  onDateClicked: () => unknown;
  onBookingClicked: (booking: SiteCalendarBookingFragment) => unknown;
}

const expandToFullWeeks = (calendar: SiteCalendarDay[], timezone: string): SiteCalendarDay[] => {
  const firstOfMonth = DateTime.fromMillis(calendar[0].date, { zone: timezone });
  const lastOfMonth = DateTime.fromMillis(calendar[calendar.length - 1].date, { zone: timezone });

  const numDaysBefore = firstOfMonth.diff(firstOfMonth.startOf("week")).as("days");
  const numDaysAfter = lastOfMonth.endOf("week").startOf("day").diff(lastOfMonth).as("days");

  const daysBefore: SiteCalendarDay[] = Array(numDaysBefore)
    .fill(null)
    .map((_, i) => ({
      date: firstOfMonth.minus({ days: numDaysBefore }).plus({ days: i }).toMillis(),
      bookings: [],
      numMore: 0,
    }));
  const daysAfter: SiteCalendarDay[] = Array(numDaysAfter)
    .fill(null)
    .map((_, i) => ({
      date: lastOfMonth
        .startOf("day")
        .plus({ days: i + 1 })
        .toMillis(),
      bookings: [],
      numMore: 0,
    }));

  return [...daysBefore, ...calendar, ...daysAfter];
};

const CalendarBooking: React.FC<{
  booking: SiteCalendarBookingFragment;
  onBookingClicked: () => unknown;
}> = ({ booking, onBookingClicked }) => {
  const { colors, spacers, borderRadius } = useTheme();

  return (
    <>
      <div className="booking p-3" role="button" title={booking.resource.name} onClick={onBookingClicked}>
        {booking.resource.name}
      </div>
      <style jsx>{`
        .booking {
          cursor: pointer;
          background: ${colors.transparent.black[5]};
          font-weight: 500;
          border-radius: ${borderRadius};
          margin-bottom: ${spacers.s1};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      `}</style>
    </>
  );
};

const CalendarDay: React.FC<DayProps> = ({ day, selectedMonth, onDateClicked, onBookingClicked }) => {
  const { colors, spacers } = useTheme();
  const { timezone } = useSiteContext();
  const { t, i18n } = useTranslation();

  const SATURDAY = 6;
  const dt = DateTime.fromMillis(day.date, { zone: timezone });
  const isToday = dt.hasSame(DateTime.now().setZone(timezone), "day");
  const isSelectedMonth = dt.hasSame(DateTime.fromMillis(selectedMonth, { zone: timezone }), "month");
  const isWeekend = dt.weekday >= SATURDAY;

  // As a general rule, rolling your own custom localised formatters like this
  // is a bad idea and should be avoided.
  // However, in this case, these custom date formats are only relevant for the
  // calendar view and we explicitly *don't* want them reused elsewhere.
  const dateFormatter = new Intl.DateTimeFormat(i18n.language, { day: "numeric", timeZone: timezone });

  return (
    <>
      <div
        className={`day ${dt.toFormat("yyyy-MM-dd")} ${isWeekend ? "weekend" : ""} ${
          isSelectedMonth ? "" : "other-month"
        }`}
      >
        <div className={`date button ${isToday ? "today" : "label-text"}`} role="button" onClick={onDateClicked}>
          {dateFormatter.format(dt.toJSDate())}
        </div>
        {day.bookings.map((booking) => (
          <CalendarBooking key={booking.uuid} booking={booking} onBookingClicked={() => onBookingClicked(booking)} />
        ))}
        {day.numMore > 0 && (
          <div className="num-more label-text">{t("bookings.operations.plusMore", { count: day.numMore })}</div>
        )}
      </div>
      <style jsx>{`
        .day {
          position: relative;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: 3em ${spacers.s3} ${spacers.s3} ${spacers.s3};
          background: ${colors.white};
        }
        .day.weekend {
          background: ${colors.grayscale[3]};
        }
        .day.other-month {
          background: ${colors.grayscale[10]};
        }
        .date {
          position: absolute;
          top: 0.5em;
          left: 50%;
          margin-left: -1em;
          height: 2em;
          width: 2em;
          line-height: 2em;
          text-align: center;
          border-radius: 50%;
          cursor: pointer;
        }
        .date.today {
          background: ${colors.dark};
          color: ${colors.white};
        }
        .num-more {
          text-transform: uppercase;
          padding: ${spacers.s2} ${spacers.s3};
          font-weight: 500;
          color: ${colors.grayscale[60]};
        }
      `}</style>
    </>
  );
};

export const OperationsCalendarMonth: React.FC<Props> = ({ calendar, onDateClicked, onBookingClicked }) => {
  const { colors, borderRadius } = useTheme();
  const { timezone } = useSiteContext();
  const { i18n } = useTranslation();

  const fullCalendar = expandToFullWeeks(calendar, timezone);

  // As a general rule, rolling your own custom localised formatters like this
  // is a bad idea and should be avoided.
  // However, in this case, these custom date formats are only relevant for the
  // calendar view and we explicitly *don't* want them reused elsewhere.
  const dayOfWeekFormatter = new Intl.DateTimeFormat(i18n.language, { weekday: "short", timeZone: timezone });

  return (
    <>
      <StickyElement<HTMLDivElement>>
        {({ ref, top, isStuck }) => (
          <div className="month mt-4">
            <div ref={ref} className="headers" style={isStuck ? { position: "sticky", top } : undefined}>
              {fullCalendar.slice(0, 7).map(({ date }) => (
                <div key={date} className="day-header p-3">
                  {dayOfWeekFormatter.format(date)}
                </div>
              ))}
            </div>
            <div className="body">
              {fullCalendar.map((day) => (
                <CalendarDay
                  key={day.date}
                  day={day}
                  selectedMonth={calendar[0].date}
                  onDateClicked={() => onDateClicked(day.date)}
                  onBookingClicked={onBookingClicked}
                />
              ))}
            </div>
          </div>
        )}
      </StickyElement>
      <style jsx>{`
        .headers,
        .body {
          display: grid;
          grid-template-columns: repeat(7, minmax(0, 1fr));
        }
        .headers {
          z-index: 1;
        }
        .day-header {
          text-transform: uppercase;
          text-align: center;
          font-weight: 500;
          color: ${colors.grayscale[60]};
          background: ${colors.white};
          border-bottom: 1px solid ${colors.border};
        }
        .body {
          /* exact height of a day with 4 bookings + "N MORE" message */
          grid-auto-rows: 204px;

          /* hack to avoid double interior borders without a bunch of nth-child selectors */
          background: ${colors.border};
          grid-gap: 1px;
          overflow: hidden;
          border-bottom-left-radius: ${borderRadius};
          border-bottom-right-radius: ${borderRadius};
        }
      `}</style>
    </>
  );
};
