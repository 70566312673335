import React, { createContext } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ProgressCircle, useTheme } from "@equiem/react-admin-ui";

import type { StatusesQuery } from "../../../generated/requests-client";
import { useStatusesQuery } from "../../../generated/requests-client";

export const RequestsStatusesContext = createContext<StatusesQuery | undefined>(undefined);

interface P {
  children?: React.ReactNode;
}
export const RequestsStatusesProvider: React.FC<P> = ({ children }) => {
  const showError = useShowError();

  const { data, loading, error } = useStatusesQuery({
    fetchPolicy: "network-only",
  });
  const { t } = useTranslation();
  const { colors } = useTheme();

  if (error != null) {
    showError(error);
    return null;
  }
  if (loading) {
    return (
      <>
        <div className="container">
          <ProgressCircle size="xs" className="mr-3" />
          {t("requests.list.loadingStatuses")}...
        </div>
        <style jsx>{`
          .container {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            background: ${colors.white};
          }
        `}</style>
      </>
    );
  }
  if (data == null) {
    return null;
  }

  return <RequestsStatusesContext.Provider value={data}>{children}</RequestsStatusesContext.Provider>;
};
